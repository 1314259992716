import React, { useEffect, useState } from 'react'
//:- Import bootstrap components
import { Button, Col, Container, Row, Stack, Table } from 'react-bootstrap'
//:- Import data table & it's all component
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader,
} from "react-bs-datatable";

// :- Import Spark Api Object
import SparkApi from '../api/SparkApi';
import { BrowserView } from 'react-device-detect';
import InfoPill from './InfoPill';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Confirm from './Confirm';
import TaskEdit from './TaskEdit';
import TaskView from './TaskView';
import TaskCreate from './TaskCreate';

// :- Export the TaskList component as Default
export default function TaskList() {
    // :Initialize the state for data table body 
    const [body, setBody] = useState(null);
    // New Change :  added the state to point specific task
    const [task, setTask] = useState('');

    // New Change :  Added the state for Create Task Model Visibility 
    const [showCreateTaskModal, setShowCreateTaskModal] = useState(false);
    // New Change : Added the state for Edit Task Model Visibility 
    const [modalShowTask, setModalShowTask] = useState(false);
    // New Change : Added the state for View Task Model Visibility 
    const [modalShowTaskView, setModalShowTaskView] = useState(false);
    // New Change : Added the state for Delete Task Model Visibility 
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    // New change: get the URL for navigate on parent According To Parent Table
    const getURLAccordingToParentTable = (row) => {
        switch (row?.parenttable) {
            case "lead":
                return (`/leads/${row.parentid}`);

            case "contact":
                return (`/contacts/${row.parentid}`);

            case "account":
                return (`/accounts/${row.parentid}`);

            case "business":
                return (`/business/${row.parentid}`);

            default:
                // return(`/leads/${row.parentid}`);
                return "/";
        }
    }



    //:- Create a functionality to fetch tasks data and set it to body state
    async function init() {
        const result = await SparkApi.getTaskByOwner();

        // :- Check data is correct or successfully fetch
        if (result && Array.isArray(result) && result?.length > 0) {
            setBody(result);
        }
        else {
            setBody([]);
        }
    }
    useEffect(() => {
        init();
    }, []);

    // New Change : added the functionality to view task
    const viewTask = (row) => {
        setModalShowTaskView(true)
        setTask(row);
    }

    // New Change : added the Edit task functionality
    const editTask = (row) => {
        setModalShowTask(true)
        setTask(row);
    }

    // New Change : added the Delete task functionality
    const handleDelete = (row) => {
        setShowConfirmationModal(true);
        setTask(row);
    }

    // // New Change : added the functionality for Delete the task form database 
    const deleteTask = async () => {
        ////console.log('task.id',task.id)
        const result = await SparkApi.deleteTask(task.id);
        ////console.log('result',result)
        if (result.success) {
            setTask('');
            setShowConfirmationModal(false);
            init();
        }
    }

    // New Change : Added the submit task functionality hide the models after task submitted
    const submitTasks = () => {
        setModalShowTask(false);
        init();
    }

    const refetchAllRecords = () => { init() }
    // :-here we Initialize the header(column) for data table
    const header = [

        {
            title: 'Title',
            prop: 'title',
            isFilterable: true,
            cell: (row) => (
                <Link
                    onClick={() => viewTask({ row })}
                // state={parent}
                >
                    {row?.title}
                </Link>
            )
        },
        {
            title: 'Type',
            prop: 'type',
            isFilterable: true,
        },
        {
            title: 'Assigned',
            prop: 'ownername',
            isFilterable: true,
            cell: (row) => (
                <Link
                    to={`/users/${row?.ownerid}`}
                >
                    {row?.ownername}
                </Link>
            )

        },


        {
            title: 'Status',
            prop: 'status',
            isFilterable: true,
        },
        {
            title: 'Priority',
            prop: 'priority',
            isFilterable: true,
        },

        {
            title: 'Target Date',
            prop: 'targetdate',
            cell: (row) => row?.targetdate ? (moment(row.targetdate).format('DD-MM-YYYY')) : ""
        },
        {
            title: 'Created Date',
            prop: 'createddate',
            isSortable: true,
            cell: (row) => row?.createddate ? (moment(row.createddate).format('DD-MM-YYYY')) : ""
        },
        {
            title: 'Parent',
            prop: 'parenttable',
            cell: (row) => {
                return row?.parenttable ?
                    <Link
                        to={getURLAccordingToParentTable(row)}
                    >
                        {row.parenttable}
                    </Link>
                    :
                    ""
            }
        },
        {
            title: 'Actions',
            prop: 'id',
            cell: (row) => (
                <>
                    {row.type !== 'Email' && <Button className='btn-sm mx-2' onClick={() => editTask({ row })}><i className="fa-regular fa-pen-to-square"></i></Button>}
                    <Button className='btn-sm mx-2' variant='danger' onClick={() => handleDelete(row)}><i class="fa-regular fa-trash-can"></i></Button>
                </>
            )
        }
    ]


    return (
        // :- Added a container component 
        <Container className='py-3'>
            {/* :- Check body is correct or body has data to show */}
            {(body && Array.isArray(body) && body.length > 0) &&
                // :-Then show data table 
                <DatatableWrapper
                    // :- Set the body here
                    body={body}
                    // :- Set the header here
                    headers={header}
                    // :-pagination info.
                    paginationOptionsProps={{
                        initialState: {
                            rowsPerPage: 10,
                            options: [5, 10, 15, 20],
                        },
                    }}

                    sortProps={{ initialState: { prop: "createddate", order: 'desc' } }}

                >
                    {/*:- Added a Row component at the top of data table */}
                    <Row className="mb-4">
                        {/* :- Add filter here*/}
                        <Col
                            xs={12}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                            <Filter />
                        </Col>
                        <Col>
                            {/* :- Add pagination dropdown or total info pill here */}
                            <Stack direction='horizontal'>

                                <PaginationOptions labels={{ "beforeSelect": " " }} />

                                <BrowserView className="d-flex align-items-end">
                                    <InfoPill left="Total" right={body?.length} />
                                </BrowserView>
                            </Stack>
                        </Col>

                        <Col className='d-flex justify-content-end align-items-end'>
                            <Button
                                className="float-end"
                                size='sm'
                                variant="outline-secondary"
                                onClick={() => setShowCreateTaskModal(true)}
                            >
                                New Task
                            </Button>
                        </Col>
                    </Row>
                    <Table striped className="data-table" responsive="sm">
                        <TableHeader />
                        <TableBody />
                    </Table>
                    <Pagination />
                </DatatableWrapper>}

            {showConfirmationModal &&
                <Confirm
                    show={showConfirmationModal}
                    onHide={() => setShowConfirmationModal(false)}
                    deleteTask={deleteTask}
                    title="Confirm delete?"
                    message="You are going to delete the record. Are you sure?"
                    table="task"
                />}
            {modalShowTask &&
                <TaskEdit
                    show={modalShowTask}
                    onHide={() => setModalShowTask(false)}
                    parentid={task?.parentid}
                    task={task}
                    table={task?.parenttable}
                    parenttable={task?.parenttable}
                    submitTasks={submitTasks}
                />
            }
            {modalShowTaskView &&
                <TaskView
                    show={modalShowTaskView}
                    onHide={() => setModalShowTaskView(false)}
                    parentid={task?.parentid}
                    task={task}
                    table="lead"
                    submitTasks={submitTasks}
                />
            }

            {showCreateTaskModal &&
                <TaskCreate
                    showTaskModel={showCreateTaskModal}
                    setShowTaskModel={setShowCreateTaskModal}
                    // New Change : added submit task here it's used for reload the new created lead
                    submitTasks={refetchAllRecords}
                />
            }
        </Container>
    )
}
