import React, { useState, useEffect, forwardRef, useImperativeHandle, useRef } from 'react'
import { Button, Col, Row, Spinner, Table } from 'react-bootstrap';
import {
    DatatableWrapper,
    Filter,
    Pagination,
    PaginationOptions,
    TableBody,
    TableHeader
} from 'react-bs-datatable';
import SparkApi from "../api/SparkApi";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import jwt_decode from "jwt-decode";
import * as constants from '../constants/CONSTANT';
import Image from 'react-bootstrap/Image'
import fileDownload from 'js-file-download';

const RelatedLocationHistory = (props) => {
    //console.log('fedfsdfs')
    const [modalShow, setModalShow] = React.useState(false);
    const [usertracking, setUsertracking] = React.useState('');
    const [imagebody, setimagebody] = useState([]);
    const [body, setBody] = useState([]);
    // New Change : added state for spinner logic
    const [showSpinner, setShowSpinner] = useState(true);

    useEffect(() => {

        alllocationhistoryaccess()


    }, []);

    const submitUsertrackings = () => {
        setModalShow(false);
        alllocationhistoryaccess();
    }

    const editUserTracking = (row) => {
        setModalShow(true)
        setUsertracking(row.row);
    }

    const alllocationhistoryaccess = async () => {
        try {
            let usertrackings = await SparkApi.fetchUsertrackingsWithstaffId(props.parent.id);
            //console.log('usertrackings', usertrackings);

            if (usertrackings && usertrackings.length > 0) {
                let arr = [];
                for (let i = 0; i < usertrackings.length; i++) {
                    let obj = usertrackings[i];
                    let files = await SparkApi.fetchFiles(obj.id); // Assuming obj.id is used to fetch files for each usertracking entry
                    //console.log('files',files)
                    if (files && files.length > 0) {
                        for (let j = 0; j < files.length; j++) {
                            let file = files[j];
                            if (file.filetype === 'jpg' || file.filetype === 'jpeg' || file.filetype === 'png') {
                                let result = await SparkApi.downloadFiles(file.id);
                                obj['body'] = window.URL.createObjectURL(result);
                            } else {
                                obj['body'] = '';
                            }
                            console.log('obj', obj)
                            arr.push({ ...obj }); // Pushing a copy of obj to avoid reference issues
                        }
                    }
                }

                setBody(usertrackings, [...arr]);
            } else {
                setBody([]);
            }
            // New Change : hide the spinner when data is fetched successfully
            setShowSpinner(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle the error, e.g., show an error message to the user
            setBody([]);
        }
    };


    //('imagebody', imagebody)
    const downloadFile = async (row) => {
        ////('if file download call',row);
        const result = await SparkApi.downloadFiles(row.id);
        if (result) {
            fileDownload(result, row.title);
        }
    }

    const fileSize = (bytes) => {
        var exp = (bytes / 1024) / 1024;
        return exp.toFixed(2) + " MB";
    }

    const renderPreview = (body) => {
        return <Image src={body} thumbnail width="200px" />;

    };


    const header = [
        {
            title: 'Login Time', prop: 'logindatetime', cell: (row) => (moment(row.logindatetime).format('DD-MM-YYYY hh:mm A'))
        },
        {
            title: 'Logout Time',
            prop: 'logoutdatetime',
            cell: (row) => (row.logoutdatetime ? moment(row.logoutdatetime).format('DD-MM-YYYY hh:mm A ') : '')
        },
        { title: 'Location', prop: 'location' },
        // { title: 'Attendance Type', prop: 'attandancetype' },
        { title: 'Remarks', prop: 'remarks' },
        {
            title: 'Location Image', prop: 'body', cell: (row) => (
                <>
                    <center><a href='javascript:void(0)' onClick={() => downloadFile(row)} >{renderPreview(row.body)} </a></center>
                </>
            )
        },
    ];


    return (
        <>
            {/* New Change : here we add the spinner when data is load */}
            {showSpinner ?
                <div className="d-flex justify-content-center align-items-center" >
                    <Spinner animation="border" variant="secondary" size="lg" />
                </div>
                :
                body &&
                <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 5
                    }
                }}>
                    <Row className="mb-4">
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                        </Col>
                    </Row>
                    <Table striped className="related-list-table" responsive="sm">
                        <TableHeader />
                        <TableBody />
                    </Table>
                    <Pagination />
                </DatatableWrapper>
            }

        </>
    )
};

export default RelatedLocationHistory;


export const RelatedCheckInHistory = (props) => {
    //console.log('fedfsdfs')
    const [modalShow, setModalShow] = React.useState(false);
    const [usertracking, setUsertracking] = React.useState('');
    const [imagebody, setimagebody] = useState([]);
    const [body, setBody] = useState([]);

    // New Change : added state for spinner logic
    const [showSpinner, setShowSpinner] = useState(true);
    useEffect(() => {

        alllocationhistoryaccess()


    }, []);

    const submitUsertrackings = () => {
        setModalShow(false);
        alllocationhistoryaccess();
    }

    const editUserTracking = (row) => {
        setModalShow(true)
        setUsertracking(row.row);
    }

    const alllocationhistoryaccess = async () => {
        try {
            let usertrackings = await SparkApi.fetchCheckInWithStaffId(props.parent.id);
            //console.log('usertrackings', usertrackings);

            if (usertrackings && usertrackings.length > 0) {
                let arr = [];
                for (let i = 0; i < usertrackings.length; i++) {
                    let obj = usertrackings[i];
                    let files = await SparkApi.fetchFiles(obj.id); // Assuming obj.id is used to fetch files for each usertracking entry
                    //console.log('files',files)
                    if (files && files.length > 0) {
                        for (let j = 0; j < files.length; j++) {
                            let file = files[j];
                            if (file.filetype === 'jpg' || file.filetype === 'jpeg' || file.filetype === 'png') {
                                let result = await SparkApi.downloadFiles(file.id);
                                obj['body'] = window.URL.createObjectURL(result);
                            } else {
                                obj['body'] = '';
                            }
                            console.log('obj', obj)
                            arr.push({ ...obj }); // Pushing a copy of obj to avoid reference issues
                        }
                    }
                }

                setBody(usertrackings, [...arr]);
            } else {
                setBody([]);
            }
            // New Change : hide the spinner when data is fetched successfully
            setShowSpinner(false);
        } catch (error) {
            console.error('Error fetching data:', error);
            // Handle the error, e.g., show an error message to the user
            setBody([]);
        }
    };


    //('imagebody', imagebody)
    const downloadFile = async (row) => {
        ////('if file download call',row);
        const result = await SparkApi.downloadFiles(row.id);
        if (result) {
            fileDownload(result, row.title);
        }
    }

    const fileSize = (bytes) => {
        var exp = (bytes / 1024) / 1024;
        return exp.toFixed(2) + " MB";
    }

    const renderPreview = (body) => {
        return <Image src={body} thumbnail width="200px" />;

    };


    const header = [
        {
            title: 'Check In Time', prop: 'logindatetime', cell: (row) => (moment(row.logindatetime).format('DD-MM-YYYY hh:mm A'))
        },
        {
            title: 'Check Out Time',
            prop: 'logoutdatetime',
            cell: (row) => (row.logoutdatetime ? moment(row.logoutdatetime).format('DD-MM-YYYY hh:mm A ') : '')
        },
        { title: 'Location', prop: 'location' },
        // { title: 'Attendance Type', prop: 'attandancetype' },
        // Added New Fields (According To Client Request on 07-Nov-2024)
        { title: 'Client Name', prop: 'client_name' },
        { title: 'Phone', prop: 'phone_no' },
        { title: 'Referral Partner Name', prop: 'referral_partner_name' },
        { title: 'Referral Number', prop: 'referral_number' },
        { title: 'postal_address', prop: 'postal_address' },
        //End Here
        { title: 'Remarks', prop: 'remarks' },
        {
            title: 'Location Image', prop: 'body', cell: (row) => (
                <>
                    <center><a href='javascript:void(0)' onClick={() => downloadFile(row)} >{renderPreview(row.body)} </a></center>
                </>
            )
        },
    ];


    return (
        <>
            {/* New Change : here we add the spinner when data is load */}
            {showSpinner ?
                <div className="d-flex justify-content-center align-items-center" >
                    <Spinner animation="border" variant="secondary" size="lg" />
                </div>
                :
                body &&
                <DatatableWrapper body={body} headers={header} paginationOptionsProps={{
                    initialState: {
                        rowsPerPage: 5
                    }
                }}>
                    <Row className="mb-4">
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-start align-items-start"
                        >
                        </Col>
                        <Col
                            xs={12}
                            sm={6}
                            lg={4}
                            className="d-flex flex-col justify-content-end align-items-end"
                        >
                        </Col>
                    </Row>
                    <Table striped className="related-list-table" responsive="sm">
                        <TableHeader />
                        <TableBody />
                    </Table>
                    <Pagination />
                </DatatableWrapper>}
        </>
    )
};


