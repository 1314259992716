import React, { useState, useEffect } from 'react'

import { Col, Form, FormSelect, Modal, Row, Stack, Button } from 'react-bootstrap';
import SparkApi from "../api/SparkApi";
import { propTypes } from 'react-bootstrap/esm/Image';

// :- Add a task model for add the task
export default function TaskCreate({ showTaskModel, setShowTaskModel, submitTasks }) {
    // :- here we initialize the states
    const [task, setTask] = useState({parenttable:"lead"});
    const [leads, setLeads] = useState(null);
    const [validated, setValidated] = useState(false);
    const [users, setUsers] = useState(null);
    // :- create a functionality to fetch leads for tasks & set it to state
    async function init() {
      const result = await SparkApi.fetchLead();
      console.log("Leads", result);
      // :- If leads fount successfully 
      if (result && Array.isArray(result) && result?.length > 0) {
        setLeads(result?.map((item) => { return { "value": item.id, "label": `${item.firstname} ${item.lastname}` } }))
      }
      const usersResult = await SparkApi.fetchUsers();
      if (usersResult) {
        setUsers(usersResult.map((item) => {
          return {
            value: item.id,
            label: item.username
          };
        }));
      } else {
        setUsers([]);
      }
    }
    useEffect(() => {
      init();
    }, [])
  
    const handleChange = (e) => {
      setTask({ ...task, [e.target.name]: e.target.value });
    };
    const checkRequiredFields = () => {
      if (
        task?.parentid && task?.parentid?.trim() !== '' &&
        task.title && task.title.trim() !== '' &&
        task.priority && task.priority.trim() !== '' &&
        task.status && task.status.trim() !== '' &&
        task?.targetdate && task?.targetdate?.trim() !== '' &&
        task.ownerid && task.ownerid.trim() !== ''
      ) {
        return false;
      }
  
      return true;
    }
    const handleSubmit = async (e) => {
      e.preventDefault();
      if (checkRequiredFields()) {
        setValidated(true);
        return;
      }
      const result = await SparkApi.createTask(task);
      if (result) {
        submitTasks && submitTasks();
        setShowTaskModel(false);
      }
    }
    return <Modal
      show={showTaskModel}
      onHide={() => setShowTaskModel(false)}
      dialogClassName="modal-90w"
      aria-labelledby="Task-Model"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="Task_Model" className=''>
          Create Task
        </Modal.Title>
      </Modal.Header>
      <Form noValidate validated={validated} onSubmit={handleSubmit} >
        <Modal.Body>
  
          <Row>
            <Col lg={6}>
              <Form.Group className="mx-3" controlId="formBasicTitle">
                <Form.Label className="form-view-label" htmlFor="formBasicTitle">
                  Lead
                </Form.Label>
                <FormSelect required name="parentid" onChange={handleChange} value={task?.parentid || ""}>
                  <option value={""}>--Select Lead--</option>
                  {(leads && Array.isArray(leads) && leads?.length > 0) && leads.map((lead) => <option value={lead.value}>{lead.label}</option>)}
                </FormSelect>
              </Form.Group>
            </Col>
  
            <Col lg={6}>
              <Form.Group className="mx-3" controlId="formBasicTitle">
                <Form.Label className="form-view-label" htmlFor="formBasicTitle">
                  Title
                </Form.Label>
                <Form.Control
                  required
                  type="text"
                  name="title"
                  placeholder="Enter title"
                  value={task.title}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
  
            <Col lg={6}>
              <Form.Group className="mx-3" controlId="formBasicType">
                <Form.Label className="form-view-label" htmlFor="formBasicType">
                  Type
                </Form.Label>
                <Form.Select
                  aria-label="Enter Payment Status"
                  name="type"
                  value={task.type}
                  onChange={handleChange}
                >
                  <option value=''>--Select Type--</option>
                  <option value="Call">Call</option>
                  <option value="Task">Task</option>
                  <option value="Email">Email</option>
                  <option value="Meeting">Meeting</option>
                  <option value="Notes">Notes</option>
                </Form.Select>
              </Form.Group>
            </Col>
  
            <Col lg={6}>
              <Form.Group className="mx-3" controlId="formBasicPriority">
                <Form.Label className="form-view-label" htmlFor="formBasicPriority">
                  Priority
                </Form.Label>
                <Form.Select required aria-label="Enter Priority" name="priority" value={task.priority} onChange={handleChange}>
                  <option value="">--Select Priority--</option>
                  <option value="Low">Low</option>
                  <option value="Normal">Normal</option>
                  <option value="High">High</option>
                </Form.Select>
              </Form.Group>
            </Col>
  
            <Col lg={6}>
              <Form.Group className="mx-3" controlId="formBasicStatus">
                <Form.Label className="form-view-label" htmlFor="formBasicStatus">
                  Status
                </Form.Label>
                <Form.Select required aria-label="Enter status" name="status" value={task.status} onChange={handleChange}>
                  <option value="">--Select Status--</option>
                  {/* <option value="Not Started">Not Started</option>
                                                  <option value="In Progress">In Progress </option>
                                                  <option value="Completed">Completed</option>
                                                  <option value="Waiting">Waiting</option>
                                                  <option value="Deferred">Deferred</option> */}
                  <option value="1st Call">1st Call</option>
                  <option value="Call Attempt Activity">Call Attempt Activity</option>
                  <option value="Callback">Callback</option>
                  <option value="Follow-Up">Follow-Up</option>
                  <option value="WhatsApp Brochure Shared">WhatsApp Brochure Shared</option>
                  <option value="Lead Not Enquired">Lead Not Enquired</option>
                  <option value="Future Follow-Up">Future Follow-Up</option>
                  <option value="Do Not Disturb">Do Not Disturb</option>
                  <option value="Completed">Completed</option>
                </Form.Select>
              </Form.Group>
            </Col>
  
            <Col lg={6}>
              <Form.Group className="mx-3" controlId="formBasicTargetdate">
                <Form.Label className="form-view-label" htmlFor="formBasicTargetdate">
                  Target date
                </Form.Label>
                <Form.Control
                  required
                  type="date"
                  name="targetdate"
                  placeholder="Enter targetdate"
                  value={task.targetdate}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
  
            <Col lg={6}>
              <Form.Group className="mx-3" controlId="formBasicOwner">
                <Form.Label className="form-view-label" htmlFor="formBasicOwner">
                  Assign Staff
                </Form.Label>
                <FormSelect required name="ownerid" onChange={handleChange} value={task?.ownerid || ""}>
                  <option value={""}>--Select Assign Staff--</option>
                  {(users && Array.isArray(users) && users?.length > 0) && users.map((user) => <option value={user.value}>{user.label}</option>)}
                </FormSelect>
              </Form.Group>
            </Col>
  
            <Col lg={12}>
              <Form.Group className="mx-3" controlId="formBasicDescription">
                <Form.Label className="form-view-label" htmlFor="formBasicDescription">
                  Description
                </Form.Label>
                <Form.Control
                  as="textarea"
                  name="description"
                  placeholder="Enter description"
                  value={task.description}
                  onChange={handleChange}
                />
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button type='submit'>Save</Button>
        </Modal.Footer>
      </Form>
    </Modal >
  }