/* eslint-disable no-lone-blocks */
import React, { useState, useEffect } from "react";
import { Alert, Col, Container, InputGroup, Row, Image, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import "react-bootstrap-typeahead/css/Typeahead.css";
import SparkApi from "../api/SparkApi";
import { useLocation } from "react-router-dom";
import PubSub from 'pubsub-js';
import Select from 'react-select';
import CityState from "../constants/CityState.json";
import jwt_decode from "jwt-decode";

import { MultiSelect } from "react-multi-select-component";
import moment from "moment";
import moment_tz from 'moment-timezone';
//Import Model
import Modal from "react-bootstrap/Modal";
import * as constants from '../constants/CONSTANT';
// New Change : added country code json for phone field 18 Oct 2024
import countryCodes from "../constants/CountryCodes.json"
const LeadEdit = () => {
    const [leadCreationIndicator, setLeadCreationIndicator] = useOutletContext();
    const [validated, setValidated] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState([]);

    const location = useLocation();
    const navigate = useNavigate();
    const [optionContact, setOptionContact] = useState({});
    const [selectedLeadSource, setSelectedLeadSource] = useState({});
    const [selectStatus, setSelectStatus] = useState({});
    const [selectIndustry, setSelectIndustry] = useState({});
    const [selectSalutation, setSalutation] = useState({});
    const [lostReason, setLostReason] = useState(false);
    const [selectUser, setSelectUser] = useState({});
    const [name, setName] = useState("");
    const [state, setState] = useState({});
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [option, setoption] = useState([]);
    const [selectOwner, setOwnerSelect] = useState([]);
    const [leadStatusArray, setleadStatusArray] = useState(JSON.parse(localStorage.getItem('lead_status')));
    const [productOption, setProductOption] = useState([]);
    const [ownerOption, setOwnerOption] = useState([]);
    const [selectContact, setSelectContact] = useState();
    const [contactOption, setContactOption] = useState([]);
    const [selectContact2, setSelectContact2] = useState({});
    const [contactOption2, setContactOption2] = useState([]);
    const [selectAccount, setSelectAccount] = useState({});
    const [accountOption, setAccountOption] = useState([]);
    // New Change : Added by farhan khan
    const [contactArchitectOption, setContactArchitectOption] = useState([]);//Added By Farhan Khan using for all records of title fields have value "Architect"
    const [selectPartnerRef, setSelectContactPartner] = useState({}); //Added By Farhan Khan using for Selected of title fields have value "Architect"
    const [showContactModel, setContactModel] = useState(false); //Contat Model Show State Added By Farhan Khan
    const [contactRef, setContactRecord] = useState({}); //Contat Model Show State Added By Farhan Khan
    //const [rating,setRating]=useState([{}]);
    const industryValue = [
        { value: "", label: "--None--" },
        { value: "Agriculture", label: "Agriculture" },
        { value: "Apparel", label: "Apparel" },
        { value: "Banking", label: "Banking" },
        { value: "Chemicals", label: "Chemicals" },
        { value: "Communications", label: "Communications" },
    ];
    const salutationValue = [
        { value: "", label: "--None--" },
        { value: "Mr.", label: "Mr." },
        { value: "Ms.", label: "Ms." },
        { value: "Dr.", label: "Dr." },
        { value: "Mrs..", label: "Mrs.." },
        { value: "Prof.", label: "Prof.." },
    ]
    const roleValue = [
        { value: "", label: "--None--" },
        { value: "ADMIN", label: "Admin" },
        { value: "USER", label: "User" },
        // {value:"Dr.",label:"Dr."},
        // {value:"Mrs..",label:"Mrs.."},
        // {value:"Prof.",label:"Prof.."},
    ]
    const leadSource = [
        { value: "Web", label: "Web" },
        { value: "Phone Enquiry", label: "Phone Enquiry" },
        { value: "Partner Referral", label: "Partner Referral" },
        { value: "Purchased List", label: "Purchased List" },
        { value: "Other", label: "Other" }
    ];
    const leadStatus = leadStatusArray;
    const [show, setShow] = React.useState(false);

    const [lead, setLead] = useState(location.state ? location.state : {});

    async function fetchContacts(){
         // //Added By Farhan Khan On 04-Nov-2024 This is Using for Contact type all Architect Records
            // //Added By Farhan Khan On 04-Nov-2024 This is Using for Contact type all Architect Records
            const contactRecords = await SparkApi.fetchContactsAll();
            // console.log("contactRecords",contactRecords)
            if (contactRecords && contactRecords?.length) {
                let contactsRecord = [];
                let obj = {};
                    obj.value = null;
                    obj.label = "--Select Partner Referral--";
                    obj.phone = "";
                    obj.firstname = "";
                    obj.lastname = "";
                    // obj.title = "Architect";
                    contactsRecord.push(obj);
                contactRecords.map((item) => {
                    var obj = {};
                    obj.value = item.id;
                    obj.label = item.firstname + " " + item.lastname + " - " + item.phone;
                    obj.phone = item.phone;
                    obj.firstname = item.firstname;
                    obj.lastname = item.lastname;
                    // obj.title = "Architect";
                    contactsRecord.push(obj);
                });
                console.log("ConAr", contactsRecord);
                setContactArchitectOption(contactsRecord);
                //On Edit Time (Added By Farhan Khan)
                if (location?.state) {
                    let acc = contactsRecord.filter(
                        (acc) => acc.value === location.state.partner_ref_id
                    );
                    // console.log("acc", acc);
                    setSelectContactPartner(acc);
                }
            }
    }

    // ////console.log(:)
    let userInfo;
    useEffect(() => {
        let userInfo = jwt_decode(localStorage.getItem('token'));
        //console.log('userInfo', userInfo);

        if (location?.state) {
            ////console.log(location.state)
            let salutation = salutationValue?.filter(salutation => salutation.value === location.state.salutation)
            let source = leadSource?.filter(source => source.value === location.state.leadsource)
            let industry = industryValue?.filter(industry => industry.value === location.state.industry)
            let status = leadStatus?.filter(status => status.value === location.state.leadstatus)

            ////console.log('source',leadStatus)
            setSalutation(salutation[0]);
            setSelectedLeadSource(source[0]);
            setSelectIndustry(industry[0]);
            setSelectStatus(status[0]);
            setLostReason(location.state.iswon === false);

            ////console.log('Lead Data ', location.state)
            if (lead.id) {
                let temp = {}
                temp.value = location.state.ownerid;
                temp.label = location.state.ownername;
                setOwnerSelect(temp);

                if (location.state?.partnerreferalfirstname && location.state?.partnerreferallastname && location.state?.partnerreferalphone) {
                    let contactObj = {}
                    contactObj.value = location.state?.partnerreferalfirstname + " " + location.state?.partnerreferallastname;
                    contactObj.label = location.state?.partnerreferalfirstname + " " + location.state?.partnerreferallastname + " - " + location.state?.partnerreferalphone;
                    setSelectContact(contactObj);
                }

            } else {
                let temp = {};
                temp.value = userInfo.id;
                temp.label = userInfo.username + " - " + userInfo?.usertype;
                setOwnerSelect([temp]);
                lead.ownername = userInfo.username;
                lead.ownerid = [userInfo.id];
            }


        } else {

            let temp = {};
            temp.value = userInfo.id;
            temp.label = userInfo.username + " - " + userInfo?.usertype;
            temp.usertype = userInfo.usertype;
            setOwnerSelect([temp]);
            lead.ownername = userInfo.username;
            lead.ownerid = [userInfo.id];

        }

        async function init() {

            const fetchUser = await SparkApi.fetchUsers();
            const products = await SparkApi.fetchProducts();
            // console.log('products', products);

            // Info : This code is related to old partner referral users list
            const result = await SparkApi.fetchContacts();
            const resultAccounts = await SparkApi.fetchAccounts();

            // New Change: fetch contacts for new partner referral
            fetchContacts()
           

            // Info : This code is related to old partner referral users list
            if (result && result?.length) {
                let contacts = []
                result?.map((item) => {
                    var obj = {};
                    obj.value = item.firstname + " " + item.lastname;
                    obj.label = item.firstname + " " + item.lastname + " - " + item.phone;
                    obj.phone = item.phone;
                    obj.firstname = item.firstname;
                    obj.lastname = item.lastname;
                    contacts.push(obj);
                })
                setContactOption(contacts);
                let contacts2 = []
                result?.map((item) => {
                    var obj = {};
                    obj.value = item.id;
                    obj.label = item.firstname + " " + item.lastname;
                    obj.phone = item.phone;
                    obj.firstname = item.firstname;
                    obj.lastname = item.lastname;
                    contacts2.push(obj);
                })
                if (location.state) {
                    setSelectContact2(contacts2?.filter((e) => e.value === location?.state?.contactid))
                }
                setContactOption2(contacts2);
            }

            if (resultAccounts && resultAccounts?.length) {
                //console.log('resultAccounts',resultAccounts)
                let accounts = []
                resultAccounts?.map((item) => {
                    var obj = {};
                    obj.value = item.id;
                    obj.label = item.name;
                    accounts.push(obj);
                })
                setAccountOption(accounts);
                if (location.state) {
                    setSelectAccount(accounts?.filter((e) => e.value === location?.state?.accountid))
                }
            }

            if (products) {
                setProductOption(products);
            }

            ////console.log("fetchUser=>" + JSON.stringify(fetchUser))
            let usr = []
            fetchUser?.map((item) => {
                var obj = {};
                obj.value = item.id;
                obj.label = item.username + " - " + item.usertype;
                obj.usertype = item.usertype;
                ////console.log(" obj.label >" + obj.label)
                usr.push(obj);
            })
            console.log('usr', usr)
            setSelectUser(usr);

            if (location?.state) {
                if (location?.state.ownerid) {
                    const initialSelection = location?.state.ownerid?.map(id => ({
                        value: id,
                        label: usr.find(user => user.value === id)?.label || '' + " - " + usr.find(user => user.value === id)?.usertype || '',
                        usertype: usr.find(user => user.value === id)?.usertype || ''
                    }));
                    setOwnerSelect(initialSelection);
                }
            } else {
                let user = fetchUser.filter((data) => data?.id === userInfo?.id);
                setLead({ ...lead, 'usertype': user?.length ? user[0]?.usertype : '' });
            }

            //////console.log('CityState',CityState)
            let st = [];
            CityState?.map((item) => {
                //////console.log(item.state,item.id);
                var obj = {};
                obj.value = item.state;
                obj.label = item.state;
                //////console.log(" obj.label >"+ obj.label)
                st.push(obj);
            });
            let finalStates = {};
            st = st.filter(function (currentObject) {
                if (currentObject.value in finalStates) {
                    return false;
                } else {
                    finalStates[currentObject.value] = true;
                    return true;
                }
            });
            //console.log('st:', st);
            setState(st);
        }
        init();
    }, []);

    const handleState = (e) => {
        let filteredCities = [];
        CityState.forEach(function (obj) {
            if (obj.state === e.value) {
                filteredCities.push({
                    label: obj.name,
                    value: obj.name
                })
            }
        });
        setCities(filteredCities);
        setLead({ ...lead, 'state': e.value });
    }

    const handleSelectListChange = (value, name) => {
        ////console.log('contact:', lead);
        setLead({ ...lead, [name]: value.value });
        setSelectedCity(value.value);

    }
    //Access all the value input values 
    const handleChange = (e) => {
        // console.log("e.target : ", e.target.name, e.target.value);
        let temp = { ...lead }
        ////console.log("temp : ", temp)
        // New Change : if filed is country_code_phone then concat it's value to phone filed 18 Oct 2024
        if (e.target.name == "country_code_phone") {
            setLead({ ...lead, "phone": `${e.target.value}${e.target.value && ' '}${lead?.phone?.split(" ").length > 1 ? lead?.phone?.split(" ")[1] : lead?.phone || ""}` });
        }
        else if (e.target.name == "phone") {
            setLead({ ...lead, "phone": `${lead?.phone?.split(" ").length > 1 ? lead?.phone?.split(" ")[0] && `${lead?.phone?.split(" ")[0]} ${e.target.value}` : e.target.value}` });
            console.log("phone", lead.phone);
        }
        // New Change : if filed is country_code_Whatsapp then concat it's value to whats_no filed 18 Oct 2024
        else if (e.target.name == "country_code_Whatsapp") {
            setLead({ ...lead, "whats_no": `${e.target.value}${e.target.value && ' '}${lead?.whats_no?.split(" ").length > 1 ? lead?.whats_no?.split(" ")[1] : lead?.whats_no || ""}` });
        }
        else if (e.target.name == "whats_no") {
            setLead({ ...lead, "whats_no": `${lead?.whats_no?.split(" ").length > 1 ? lead?.whats_no?.split(" ")[0] && `${lead?.whats_no?.split(" ")[0]} ${e.target.value}` : e.target.value}` });
        }
        // New Change : if filed is country_code_other_phone then concat it's value to other_phone filed 18 Oct 2024
        else if (e.target.name == "country_code_other_phone") {
            setLead({ ...lead, "other_phone": `${e.target.value}${e.target.value && ' '}${lead?.other_phone?.split(" ").length > 1 ? lead?.other_phone?.split(" ")[1] : lead?.other_phone || ""}` });
        }
        else if (e.target.name == "other_phone") {
            setLead({ ...lead, "other_phone": `${lead?.other_phone?.split(" ").length > 1 ? lead?.other_phone?.split(" ")[0] && `${lead?.other_phone?.split(" ")[0]} ${e.target.value}` : e.target.value}` });
        }
        // New Change : follow_up field validation if we want
        else if (e.target.name == "follow_up") {
            setLead({ ...lead, "follow_up": e.target.value });
        }
        else {
            setLead({ ...lead, [e.target.name]: e.target.value });
        }

        if (e.target.name === 'leadstatus') {
            leadStatus?.map((status) => {
                if (status.label === e.target.value) {
                    if (status.is_lost === true) {
                        setLostReason(true);
                        return;
                    } else {
                        setLostReason(false);
                        return;
                    }
                }
            });

        }
        ////console.log("lead:", lead);

    };

    const handleRoleChange = (e) => {
        console.log('event', e)
        setoption(e)
        setLead({ ...lead, 'usertype': e.usertype, 'ownerid': e.value, ownername: e.label });
    }


    const handleContactChange = (e) => {
        ////console.log('event', e)
        setSelectContact(e)
        setLead({ ...lead, partnerreferalfirstname: e.firstname, partnerreferallastname: e.lastname, partnerreferalphone: e.phone });
    }

    const handleContactChange2 = (e) => {
        ////console.log('event', e)
        setSelectContact2(e)
        setLead({ ...lead, contactid: e?.value });
    }

    const handleAccountChange = (e) => {
        ////console.log('event', e)
        setSelectAccount(e)
        setLead({ ...lead, accountid: e?.value });
    }


    const handleSubmit = async (e) => {
        // console.log('handle submit call', lead)
        e.preventDefault();


        // ---------------------------------------\
        const validation = checkRequredFields()
        // console.log("Validate",validation)
        if (validation) {
            // console.log('yes validate');
            setValidated(true);
            PubSub.publish('RECORD_ERROR_TOAST', { title: 'ERROR', message: 'Please fill in all required fields' });
            return;
        }

        // New changes : Implement the validation for the country code or phone fields :

        if (!(/^(\+[1-9]{1}[0-9]{0,5} |)[0-9]{10}$/.test(lead.phone))) {
            console.log("Error in phone", lead?.phone);
            PubSub.publish('RECORD_ERROR_TOAST', { title: 'ERROR', message: 'Invalid phone or incorrect country code' });
            setValidated(true);
            return;
        }

        if (lead?.whats_no && !(/^(\+[1-9]{1}[0-9]{0,5} |)[0-9]{10}$/.test(lead.whats_no))) {
            console.log("Error in whats_no", lead?.whats_no);
            PubSub.publish('RECORD_ERROR_TOAST', { title: 'ERROR', message: 'Invalid whatsapp or incorrect country code' });
            setValidated(true);
            return;
        }

        if (lead?.other_phone && !(/^(\+[1-9]{1}[0-9]{0,5} |)[0-9]{10}$/.test(lead.other_phone))) {
            console.log("Error in other_phone", lead?.other_phone);
            PubSub.publish('RECORD_ERROR_TOAST', { title: 'ERROR', message: 'Invalid other phone or incorrect country code' });
            setValidated(true);
            return;
        }

        //========= Logic to perform Create or Edit ======
        let result = {};

        let iswon = null;

        leadStatus?.map((status) => {
            //console.log('status:', status);
            //console.log('lead.leadstatus:', lead.leadstatus);
            if (status.label === lead.leadstatus) {
                if (status.is_converted === true) {
                    iswon = true;
                } else if (status.is_lost === true) {
                    iswon = false;
                }
            }
        });

        lead.iswon = iswon;

        console.log('test')
        let userInfo = jwt_decode(localStorage.getItem('token'));
        // console.log("UserInfoLog?",userInfo);
        if (!lead?.adminownerid && !lead?.id && userInfo?.userrole === 'SUPER_ADMIN') {

            let adminUsers = await SparkApi?.fetchUserByUsertype('Sales Admin');
            let string = `{ `
            for (let data of adminUsers) {
                string += `${data?.id}, `
            }
            string = string.replace(/,\s*$/, "")
            string += `}`;
            //console.log('string',string)
            lead.adminownerid = string;
        } else {
            if (userInfo?.usertype === 'Sales Admin' && lead?.leadstatus === 'Transfer to Installation & Accounts') {
                let adminUsers = await SparkApi?.findByUserTypeWithAdmin();
                let string = `{ `
                // console.log("lead?.adminownerid", lead?.adminownerid)
                if (lead?.adminownerid) {
                    for (let data of lead?.adminownerid) {
                        string += `${data}, `
                    }
                }
                for (let data of adminUsers) {
                    string += `${data?.id}, `
                }
                string = string.replace(/,\s*$/, "")
                string += `}`;
                //console.log('string',string)
                lead.adminownerid = string;
            }
        }


        if (lead.id) {
            // New Change : Added by farhan khan

            const descriptions = `User Name: ${userInfo.username}, `
            // lead.lead_audit_trail = descriptions;

            //console.log("lead.id: ", lead);
            ////console.log("lead: ", JSON.stringify(lead));
            // lead.product = selectedProducts
            // lead.amount = lead?.amount == '' ? 0 : lead?.amount;
            result = await SparkApi.updateLead(lead);
            ////console.log("result ===>", result);
            if (result.success) {
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
                navigate(`/leads/${lead.id}`, { state: lead });
            }
        } else {
            // setLeadCreationIndicator(leadCreationIndicator + 1);
            //  lead.product = selectedProducts
            // lead.amount = lead?.amount == '' ? 0 : lead?.amount;

            result = await SparkApi.createLead(lead);
            ////console.log('result', result)
            if (result) {
                PubSub.publish('RECORD_SAVED_TOAST', { title: 'Record Saved', message: 'Record saved successfully' });
                navigate(`/leads/${result.id}`, { state: result });
            }
        }

        console.log("user info =>", userInfo)
    };

    const checkRequredFields = () => {
        const flags = "g";
        const pattern = "^[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}$";
        const regexPattern = new RegExp(pattern, flags);

        lead.amount = !(lead?.amount) || lead?.amount == '' ? 0 : lead?.amount;



        // console.log('selectOwner', lead);
        if (lead.email && lead.email != "" && !lead.email.match(regexPattern)) return true;



        if (selectOwner && selectOwner?.length === 0) {
            console.log('if call');
            setShow(true);
            // document.querySelector(".username").style.border = "1px solid red";

        } else if ((lead.firstname && lead.firstname.trim() !== '') && (lead.lastname && lead.lastname.trim() !== '') && lead.leadstatus && (lead.area && lead.area.trim() !== '') && (lead?.amount >= 0)) {

            if (lead.leadsource === "Other") {
                // if (lead.otherarea && lead.otherarea.trim() !== "") return false;
            } else {
                lead.othersource = "";
                // return false;
            }

            if (lead.area === "Other") {
                if (lead.otherarea && lead.otherarea.trim() !== "") return false;
            } else {
                lead.otherarea = "";
                return false;
            }
            // return false;
        }

        return true;
    }

    const handleCancel = () => {
        navigate("/leads/", { state: lead });
    };



    const [selectedProducts, setSelectedProducts] = useState([]);

    const handleSelect = (selectedList) => {
        console.log('selectedList', selectedList)
        setSelectedProducts(selectedList);
        lead.product = selectedList?.map(item => item.label).join(", ")
        lead.productid = selectedList?.map(item => item.value);
        // console.log('lead.product', lead.product)
        // console.log('lead.product', lead.productid)
        // handleChange({ target: { name: 'product', value: selectedList.map(item => item.value) } });
    };

    const handleOwnerSelect = (selectedList) => {

        // console.log('selectedList', selectedList);
        (selectedList?.length != 0) ? setShow(false) : setShow(true);
        setOwnerSelect(selectedList);
        //console.log('selectedList.map(item => item.value);', selectedList.map(item => item.value))
        lead.ownerid = selectedList?.map(item => item.value);
        //console.log('lead.ownerid', lead.ownerid)
        // handleChange({ target: { name: 'product', value: selectedList.map(item => item.value) } });
    };


    const options = productOption?.map((item) => ({ label: item.productname, value: item.id }));
    console.log('selectedProducts', selectedProducts)
    console.log('options', options)

    useEffect(() => {
        if (lead.id && location?.state?.productid?.length) {
            const initialSelection = location?.state?.productid?.map(id => ({
                value: id,
                label: productOption?.find(data => data?.id === id)?.productname || '',
            }));

            setSelectedProducts(initialSelection);
        }
    }, [lead.id, lead.product, productOption]);

    //Added By Farhan Khan on 04-Nov-2024 for (partner_ref_id)
    const handleContactSelect = (e) => {
        console.log('event', e)
        setSelectContactPartner(e)
        setLead({ ...lead, partner_ref_id: e?.value });
    }

    // Added By Farhan Khan on 06-Nov-2024 (Contact Model to show for Create).
    const handleContactModel = () => {
        setContactModel(true);
    };

    const handleContactModelClose = () => {
        setContactModel(false);
    };

    const handleSubmitContact = async (e) => {
        let result = {}
        result = await SparkApi.createContact(contactRef);
        if (result) {
            setContactModel(false);
            lead.partner_ref_id = result.id;
            // fetchContacts();
            fetchContacts();
            const obj = {};
            obj.value = result.id;
            obj.label = result.firstname + " " + result.lastname + " - " + result.phone;
            obj.phone = result.phone;
            obj.firstname = result.firstname;
            obj.lastname = result.lastname;
            // obj.title = "Architect";
            // console.log("acc", acc);
            setSelectContactPartner(obj);
        }
    };

    const handleChangeContactPartner = (e) => {
        setContactRecord({ ...contactRef, [e.target.name]: e.target.value });
    };
    //End Here By Farhan Khan on 06-Nov-2024

    return (
        <Container className="view-form">
            <Row className="view-form">
                <Col></Col>
                <Col lg={8} className="pb-1 pt-2">
                    <Link className="nav-link" to="/leads">
                        Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Leads</div>
                    </Link>
                </Col>
                <Col></Col>
            </Row>
            <Row>
                <Col></Col>
                <Col lg={8}>
                    <Form className="mt-3" noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="view-form-header align-items-center">
                            <Col lg={9}>
                                {
                                    lead.id ? <h6> Edit Lead</h6> : <h6>Create Lead</h6>
                                }

                                <h5>{name}</h5>
                            </Col>
                            <Col lg={3} style={{ textAlign: "right" }}>
                                <Button className="btn-sm mx-2" onClick={handleSubmit}>
                                    Save
                                </Button>
                                <Button
                                    className="btn-sm"
                                    variant="danger"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </Button>
                            </Col>
                        </Row>
                        <Row className="ibs-edit-form mb-4" lg={12}>

                            <Col lg={12}>
                                <Alert variant="danger" show={show} className="error-alert my-3 mx-2">
                                    Please select assign staff
                                </Alert>
                            </Col>


                            <Col lg={2}>
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Salutation
                                    </Form.Label>
                                    <Form.Select
                                        style={{ height: "37px" }}
                                        aria-label="Select Salutation"
                                        name="salutation"
                                        value={lead.salutation}
                                        onChange={handleChange}
                                    >
                                        <option value="">--Select--</option>
                                        <option value="Mr">Mr.</option>
                                        <option value="Mrs">Mrs.</option>
                                        <option value="Ms">Ms.</option>
                                        <option value="Dr">Dr.</option>
                                        <option value="Prof">Prof.</option>
                                    </Form.Select>

                                </Form.Group>

                            </Col>
                            <Col lg={4}>
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        First Name
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "37px" }}
                                        type="text"
                                        name="firstname"
                                        required={true}
                                        placeholder="Enter First Name"
                                        value={lead.firstname}

                                        onChange={(e) => handleChange(e)}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide first name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLastName"
                                    >
                                        Last Name
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "37px" }}
                                        required={true}
                                        type="text"
                                        name="lastname"
                                        placeholder="Enter Last Name"
                                        value={lead.lastname}
                                        //defaultValue="Ali"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide last name.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            {/* New Change : added follow_up field for the remainder feature */}
                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Follow Up Date
                                    </Form.Label>
                                    <FormControl
                                        style={{ height: "37px" }}
                                        type="date"
                                        name="follow_up"
                                        min={moment_tz(new Date()).tz("Asia/Kolkata").format(`YYYY-MM-DD`)}
                                        onChange={(e) => handleChange(e)}
                                        value={lead?.follow_up}
                                        // New Change : prevent user to add value form key board for date field : can slect value form only calaneder
                                        onKeyDown={(e) => e.preventDefault()}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select follow up date.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicPhone"
                                    >
                                        Phone
                                    </Form.Label>
                                    {/*New Change : added a component InputGroup for CountryCode*/}
                                    <InputGroup >
                                        <InputGroup.Text
                                            placeholder="select code"
                                            as="input" style={{ width: "8rem" }}
                                            type="text" className="bg-white"
                                            list="country_code_phone"
                                            name="country_code_phone"
                                            value={lead?.phone && lead?.phone?.split(" ")?.length > 1 ? lead?.phone?.split(" ")[0] : ""}
                                            onChange={(e) => handleChange(e)} onKeyDownCapture={e => { e?.key == " " && e.preventDefault() }}
                                        />
                                        <datalist id="country_code_phone" >
                                            {countryCodes.map((countryCode) => {
                                                // console.log(countryCode.image);
                                                return (
                                                    <option
                                                        value={countryCode.dial_code}
                                                    >
                                                        {countryCode.name} <br />
                                                        {countryCode.flag}
                                                        {countryCode.dial_code}
                                                    </option>)
                                            })}
                                        </datalist>


                                        <Form.Control
                                            style={{ height: "37px" }}
                                            type="phone"
                                            name="phone"
                                            required
                                            minLength="10"
                                            maxLength="10"
                                            placeholder="Enter Phone"
                                            value={lead.phone && (lead?.phone?.split(" ").length > 1 ? lead?.phone?.split(" ")[1] : lead?.phone)}
                                            onKeyDownCapture={e => { (e?.key == " " || ((e.key.length == 1) && isNaN(e.key))) && e.preventDefault() }}
                                            onChange={(e) => handleChange(e)}
                                        />
                                        <Form.Control.Feedback type="invalid" className="">
                                            Please provide valid Phone.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>

                            {/*New Change : added  new field whats_no for whatsapp number*/}
                            <Col lg={6} >
                                <Form.Group aria-required>
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicWhatsapp"
                                    >
                                        Whatsapp
                                    </Form.Label>
                                    {/*New Change : added a component InputGroup for CountryCode*/}
                                    <InputGroup hasValidation={true} >
                                        <InputGroup.Text placeholder="select code" as="input" style={{ width: "8rem" }} type="text" className="bg-white" list="country_code_Whatsapp" name="country_code_Whatsapp" value={lead?.whats_no && lead?.whats_no?.split(" ")?.length > 1 ? lead?.whats_no?.split(" ")[0] : ""} onChange={(e) => handleChange(e)} />
                                        <datalist id="country_code_Whatsapp" >
                                            {countryCodes.map((countryCode) => {
                                                // console.log(countryCode.image);
                                                return (
                                                    <option
                                                        value={countryCode.dial_code}
                                                    >
                                                        {countryCode.name} <br />
                                                        {countryCode.flag}
                                                        {countryCode.dial_code}
                                                    </option>)
                                            })}
                                        </datalist>
                                        <Form.Control
                                            id="formBasicWhatsapp"
                                            style={{ height: "37px" }}
                                            type="phone"
                                            name="whats_no"
                                            minLength="10"
                                            maxLength="10"
                                            placeholder="Enter Whatsapp number"
                                            value={lead.whats_no && (lead?.whats_no?.split(" ").length > 1 ? lead?.whats_no?.split(" ")[1] : lead?.whats_no)}
                                            onKeyDownCapture={e => { (e?.key == " " || ((e.key.length == 1) && isNaN(e.key))) && e.preventDefault() }}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide valid Phone.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            {/*New Change : added  new field whats_no for Other phone number*/}
                            <Col lg={6} >
                                <Form.Group aria-required>
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicOtherPhone"
                                    >
                                        Other Phone
                                    </Form.Label>
                                    {/*New Change : added a component InputGroup for CountryCode*/}
                                    <InputGroup hasValidation={true}>
                                        <InputGroup.Text placeholder="select code" as="input" style={{ width: "8rem" }} type="text" className="bg-white" list="country_code_other_phone" name="country_code_other_phone" value={lead?.other_phone && lead?.other_phone?.split(" ")?.length > 1 ? lead?.other_phone?.split(" ")[0] : ""} onChange={(e) => handleChange(e)} />
                                        <datalist id="country_code_other_phone" >
                                            {countryCodes.map((countryCode) => {
                                                // console.log(countryCode.image);
                                                return (
                                                    <option
                                                        value={countryCode.dial_code}
                                                    >
                                                        {countryCode.name} <br />
                                                        {countryCode.flag}
                                                        {countryCode.dial_code}
                                                    </option>)
                                            })}
                                        </datalist>
                                        <Form.Control
                                            id="formBasicOtherPhone"
                                            style={{ height: "37px" }}
                                            type="phone"
                                            name="other_phone"
                                            minLength="10"
                                            maxLength="10"
                                            placeholder="Enter other phone"
                                            value={lead.other_phone && (lead?.other_phone?.split(" ").length > 1 ? lead?.other_phone?.split(" ")[1] : lead?.other_phone)}
                                            onKeyDownCapture={e => { (e?.key == " " || ((e.key.length == 1) && isNaN(e.key))) && e.preventDefault() }}
                                            onChange={(e) => handleChange(e)}
                                        />
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please provide valid Phone.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Account Name (New Account)
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "37px" }}
                                        type="text"
                                        name="company"
                                        placeholder="Enter Account"
                                        value={lead.company}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide an account name
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Account Name (Existing Account)
                                    </Form.Label>
                                    <Select
                                        isClearable
                                        value={selectAccount && selectAccount}
                                        //className="custom-select username"
                                        onChange={(e) => handleAccountChange(e)}
                                        options={accountOption}
                                        placeholder="--Select Account--"
                                    >
                                    </Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select an account
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Contact Name
                                    </Form.Label>
                                    <Select
                                        isClearable
                                        value={selectContact2 && selectContact2}
                                        //className="custom-select username"
                                        onChange={(e) => handleContactChange2(e)}
                                        options={contactOption2}
                                        placeholder="--Select Contact--"
                                    >
                                    </Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a contact
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            {/* Added By Farhan Khan on 04-Nov-2024 Using for Filter Records of Contact Type Architect */}
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Partners Referral
                                    </Form.Label>

                                    <InputGroup>

                                        <Select

                                            // required
                                            // className={`multi-staff custom-select rounded-3 ${show ? 'border-start-2 border-danger' : 'border-start-2 border-success'} `}
                                            className="w-75"
                                            options={contactArchitectOption}
                                            value={selectPartnerRef}
                                            onChange={handleContactSelect}
                                            placeholder="--Select Partner Referral--"
                                        />
                                        <InputGroup.Text
                                            // class="fa fa-plus fa-xl mx-3"
                                            className="d-flex justify-content-center align-items-center w-25 p-0 bg-white"
                                        >
                                            <i
                                                class="fa fa-plus fa-xl  text-success"
                                                // style={{ color: "green" }}
                                                onClick={handleContactModel}
                                            ></i>
                                        </InputGroup.Text>
                                    </InputGroup>
                                    <Form.Control.Feedback type="invalid">
                                        Please select partners referral.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {/* End Here */}


                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Email
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "37px" }}
                                        type="email"
                                        name="email"
                                        placeholder="Enter Email"
                                        value={lead.email}
                                        onChange={(e) => handleChange(e)}
                                        pattern="^[A-Za-z0-9\._%+\-]+@[A-Za-z0-9\.\-]+\.[A-Za-z]{2,}$"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide valid email
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Title
                                    </Form.Label>
                                    {/* <Form.Control

                                        type="text"
                                        name="title"
                                        placeholder="Enter Title"
                                        value={lead.title}
                                        onChange={(e) => handleChange(e)}
                                    /> */}

                                    <Form.Select value={lead.title} name="title" onChange={handleChange} style={{ height: "37px" }}>
                                        <option value="">--Select Title--</option>
                                        <option value="CEO">CEO</option>
                                        <option value="Director">Director</option>
                                        <option value="Manager">Manager</option>
                                        <option value="Owner">Owner</option>
                                        <option value="Partner">Partner</option>
                                        <option value="Executive">Executive</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select a Title.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Website
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "37px" }}
                                        type="text"
                                        name="website"
                                        placeholder="Enter Website"
                                        value={lead.website}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide website
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLeadSource"
                                    >
                                        Lead Source
                                    </Form.Label>

                                    <Form.Select aria-label="Enter status" value={lead.leadsource} name="leadsource" onChange={handleChange} style={{ height: "37px" }}>
                                        <option value="">--Select-Source--</option>
                                        <option value="Architect">Architect</option>
                                        <option value="Interior Designer">Interior Designer</option>
                                        <option value="Contractor">Contractor</option>
                                        <option value="Dealer">Dealer</option>
                                        <option value="Client">Client</option>
                                        <option value="Google">Google</option>
                                        <option value="Facebook">Facebook</option>
                                        <option value="Instagram">Instagram</option>
                                        <option value="LinkedIn">LinkedIn</option>
                                        <option value="Whatsapp">Whatsapp</option>
                                        <option value="Email">Email</option>
                                        <option value="Cold Calling">Cold Calling</option>
                                        <option value="Exhibition">Exhibition</option>
                                        <option value="Company Person">Company Person</option>
                                        <option value="Builder">Builder</option>
                                        <option value="Other">Other</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Lead Source.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {lead?.leadsource === 'Other'
                                && <Col lg={6} >
                                    <Form.Group>
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicLastName"
                                        >
                                            Other Source
                                        </Form.Label>
                                        <Form.Control
                                            style={{ height: "37px" }}
                                            type="text"
                                            name="othersource"
                                            placeholder="Enter Other Source"
                                            value={lead.othersource}
                                            //defaultValue="Ali"
                                            onChange={(e) => handleChange(e)}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            Please provide other source
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>}

                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Assigned User
                                    </Form.Label>

                                    <MultiSelect
                                        required
                                        className={`multi-staff custom-select rounded-3 ${show ? 'border-start-2 border-danger' : 'border-start-2 border-success'} `}
                                        // className="multi-staff custom-select username"
                                        options={selectUser}
                                        value={selectOwner}
                                        onChange={handleOwnerSelect}
                                        labelledBy="-- Select Assigned--"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select assign staff.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            {/*  <Col lg={6}>
                                <Form.Group >
                                    <Form.Label className="form-view-label">User Type</Form.Label>
                                    <Form.Select name="usertype" onChange={handleChange} required value={lead.usertype} disabled>
                                        <option value="">-- Select --</option>
                                        <option value="Sales Admin">Sales Admin</option>
                                                <option value="Installation Admin">Installation Admin</option>
                                                <option value="Accounts Admin">Accounts Admin</option>
                                                <option value="Installation Engineer">Installation Engineer</option>
                                                <option value="Business Development Executive">Business Development Executive</option>
                                                <option value="Customer Relationship Executive">Customer Relationship Executive</option>
                                                <option value="Office Coordinator">Office Coordinator</option>
                                                <option value="Office Executive">Office Executive</option>

                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please Select User-Type.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col> */}
                            {/* New Change : remove partner referral and enhance it to new 6 Nov */}
                            {/* <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFirstName"
                                    >
                                        Partners Referral
                                    </Form.Label>
                                    <Select

                                        value={selectContact}
                                        //className="custom-select username"
                                        onChange={(e) => handleContactChange(e)}
                                        options={contactOption}
                                        placeholder="--Select Partners Referral--"
                                    >
                                    </Select>

                                </Form.Group>
                            </Col> */}

                            <Col lg={6}>
                                <Form.Label className="form-view-label">Product</Form.Label>

                                <MultiSelect
                                    options={options}
                                    value={selectedProducts}
                                    onChange={handleSelect}
                                    labelledBy="-- Select Product --"
                                    className="multi-staff"
                                    placeholder="-- Select Product --"

                                />
                            </Col>


                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Business Type
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Industry" value={lead.industry} name="industry" onChange={handleChange} style={{ height: "37px" }}>
                                        <option value="">--Select-Industry--</option>
                                        <option value="Residencial">Residencial</option>
                                        <option value="Builders">Builders</option>
                                        <option value="Commercial">Commercial</option>
                                        <option value="Dealers">Dealers</option>
                                        <option value="Others">Others</option>
                                    </Form.Select>

                                    <Form.Control.Feedback type="invalid">
                                        please select business type
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLastName"
                                    >
                                        Lead Type
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Lead Type" value={lead.leadtype} name="leadtype" onChange={handleChange} style={{ height: "37px" }}>
                                        <option value="">--Select Type--</option>
                                        {
                                            constants.LEAD_TYPE.map((e) => (
                                                <option value={e.value}>{e.label}</option>

                                            ))
                                        }
                                    </Form.Select>
                                    {/* <Form.Control

                                        type="text"
                                        name="leadtype"
                                        placeholder="Enter Lead Type"
                                        value={lead.leadtype}
                                        //defaultValue="Ali"
                                        onChange={(e) => handleChange(e)}
                                    /> */}

                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Area
                                    </Form.Label>
                                    <Form.Select required value={lead.area} name="area" onChange={handleChange} style={{ height: "37px" }}>
                                        <option value="">--Select Area--</option>
                                        <option value="VKI & Sikar road">VKI & Sikar road</option>
                                        <option value="Vidyadhar nagar">Vidyadhar nagar</option>
                                        <option value="Bani park">Bani park</option>
                                        <option value="Vaishali Nagar">Vaishali Nagar</option>
                                        <option value="Vaishali Nagar-West">Vaishali Nagar-West</option>
                                        <option value="Mansarovar">Mansarovar</option>
                                        <option value="Patrakar colony">Patrakar colony</option>
                                        <option value="Sanganer">Sanganer</option>
                                        <option value="Gopalpura bypass">Gopalpura bypass</option>
                                        <option value="Shyam nagar/Nirman nagar">Shyam nagar/Nirman nagar</option>
                                        <option value="Durgapura">Durgapura</option>
                                        <option value="JLN Marg">JLN Marg</option>
                                        <option value="Malviya Nagar">Malviya Nagar</option>
                                        <option value="Bapu Nagar-Lal kothi">Bapu Nagar-Lal kothi</option>
                                        <option value="Jagatpura">Jagatpura</option>
                                        <option value="C-Scheme-Civil lines">C-Scheme-Civil lines</option>
                                        <option value="Jawahar nagar-Raja park">Jawahar nagar-Raja park</option>
                                        <option value="Old city">Old city</option>
                                        <option value="Amer-Delhi road">Amer-Delhi road</option>
                                        <option value="Jhotwara">Jhotwara</option>
                                        <option value="Other">Other</option>
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select Area.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {lead?.area === 'Other' && <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLastName"
                                    >
                                        Other Area
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "37px" }}
                                        required
                                        type="text"
                                        name="otherarea"
                                        placeholder="Enter Other Area"
                                        value={lead.otherarea}
                                        //defaultValue="Ali"
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide Other Area.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>}
                            {/* <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Payment Model
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Status" name="paymentmodel" onChange={handleChange} value={lead.paymentmodel}>
                                        <option value="">--Select Payment Model--</option>

                                        <option value="Subscription">
                                            Subscription
                                        </option>
                                        <option value="One Time">
                                            One Time
                                        </option>

                                    </Form.Select>
                                </Form.Group>
                            </Col> */}
                            {/* <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Payment Terms
                                    </Form.Label>
                                    <Form.Select aria-label="Enter Status" name="paymentterms" onChange={handleChange} value={lead.paymentterms}>
                                        <option value="">--Select Terms--</option>
                                        <option value="12">
                                            12 Months
                                        </option>
                                        <option value="24">
                                            24 Months
                                        </option>
                                        <option value="One Time">
                                            One Time
                                        </option>
                                        <option value="One Time with Yearly Renewal">
                                            One Time with Yearly Renewal
                                        </option>


                                    </Form.Select>
                                </Form.Group>
                            </Col> */}

                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Status
                                    </Form.Label>
                                    <Form.Select required aria-label="Enter Status" name="leadstatus" onChange={handleChange} value={lead.leadstatus} style={{ height: "37px" }}>
                                        <option value="">--Select-Status--</option>
                                        {/* Remove the disabled form the close converted status */}
                                        {leadStatusArray?.map((item, index) => (
                                            <option
                                                value={item.label}
                                                key={index}
                                            // disabled={item.label == 'Closed - Converted'}
                                            >
                                                {item.label}
                                            </option>
                                        ))}
                                    </Form.Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select status.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6}>
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicEmail"
                                    >
                                        Date of Birth
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "37px" }}
                                        type="Date"
                                        name="dob"
                                        placeholder="Enter dob"
                                        max={moment(new Date()).format('YYYY-MM-DD')}
                                        value={lead?.dob ? moment(lead.dob).format('YYYY-MM-DD') : ''}
                                        onChange={handleChange}
                                        // New Change : prevent user to add value form key board for date field : can slect value form only calaneder
                                        onKeyDown={(e) => e.preventDefault()}
                                    />
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicLastName"
                                    >
                                        Anniversary
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "37px" }}
                                        type="date"
                                        name="anniversary"
                                        placeholder="Enter Anniversary"
                                        max={moment(new Date()).format('YYYY-MM-DD')}
                                        value={lead?.anniversary ? moment(lead.anniversary).format('YYYY-MM-DD') : ''}
                                        onChange={(e) => handleChange(e)}
                                        // New Change : prevent user to add value form key board for date field : can slect value form only calaneder
                                        onKeyDown={(e) => e.preventDefault()}
                                    />

                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Sales Amount (₹)
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "37px" }}
                                        type="number"
                                        name="amount"
                                        placeholder="Enter Sales Amount"
                                        value={lead.amount && lead.amount}
                                        min={0}
                                        onKeyDownCapture={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                                        onChange={(e) => handleChange(e)}
                                        className="remove-arrow"
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Provide valid Sales Amount.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>

                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Expected Amount (₹)
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "37px" }}
                                        type="number"
                                        name="expectedamount"
                                        value={lead.expectedamount}
                                        onChange={(e) => handleChange(e)}
                                        placeholder="Enter Expected Amount"
                                        className="remove-arrow"
                                        onKeyDownCapture={e => ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault()}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide expected amount.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            {/* {lostReason &&
                                <Col lg={6} >
                                    <Form.Group  >
                                        <Form.Label
                                            className="form-view-label"
                                            htmlFor="formBasicFees"
                                        >
                                            Lost Reason
                                        </Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            name="lostreason"
                                            required
                                            placeholder="Enter lost reason"
                                            value={lead.lostreason}
                                            onChange={handleChange}
                                        />

                                    </Form.Group>
                                </Col>
                            } */}
                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicFees"
                                    >
                                        Description
                                    </Form.Label>
                                    <Form.Control
                                        as="textarea"
                                        name="description"
                                        placeholder="Enter Description"
                                        value={lead.description}
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Col>
                            <Col lg={12} className="section-header">
                                ADDRESS INFORMATION
                            </Col>
                            <Col lg={6} >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        State
                                    </Form.Label>
                                    <Select
                                        defaultValue={lead.state && { label: lead.state, value: lead.state }}
                                        onChange={handleState}
                                        options={state}
                                        placeholder="--Select State--"

                                    >
                                    </Select>
                                    <Form.Control.Feedback type="invalid">
                                        Please select state
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6}  >
                                <Form.Group  >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        City
                                    </Form.Label>
                                    <Select options={cities}
                                        placeholder="--Select City--"
                                        onChange={(e) => { handleSelectListChange(e, 'city') }}
                                        name="city"
                                        defaultValue={lead.city && { label: lead.city, value: lead.city }}


                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please select a city.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Street
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "37px" }}
                                        type="text"
                                        name="street"
                                        placeholder="Enter Street"
                                        value={lead.street}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide street.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} >
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Zip / PostalCode
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "37px" }}
                                        type="text"
                                        name="zipcode"
                                        placeholder="Enter Postal Code"
                                        value={lead.zipcode}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide postal code.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col lg={6} className="pb-3">
                                <Form.Group >
                                    <Form.Label
                                        className="form-view-label"
                                        htmlFor="formBasicCompany"
                                    >
                                        Country
                                    </Form.Label>
                                    <Form.Control
                                        style={{ height: "37px" }}
                                        type="text"
                                        name="country"
                                        placeholder="Enter Country"
                                        value={lead.country}
                                        onChange={(e) => handleChange(e)}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide country.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>


                            {/* <Col className="mt-5"></Col> */}

                        </Row>

                    </Form>
                </Col>
                <Col></Col>
            </Row>
            {/* // Model Code for Ref Partner Added By Farhan Khan on 06-Nov-2024 */}
            <Modal
                show={showContactModel}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Create Contact (Partner Referral)
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container className="view-form">
                        <Row>
                            <Col lg={12}>
                                <Form
                                    className="mt-3"
                                    noValidate
                                    validated={validated}
                                    onSubmit={handleSubmitContact}
                                    controlId="taskEdit"
                                >
                                    <Row>
                                        <Col>
                                            <Form.Group className="mx-3" controlId="formBasicTitle">
                                                <Form.Label
                                                    className="form-view-label"
                                                    htmlFor="formBasicTitle"
                                                >
                                                    First Name
                                                </Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="firstname"
                                                    placeholder="Enter First Name"
                                                    onChange={handleChangeContactPartner}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group className="mx-3" controlId="formBasicTitle">
                                                <Form.Label
                                                    className="form-view-label"
                                                    htmlFor="formBasicTitle"
                                                >
                                                    Last Name
                                                </Form.Label>
                                                <Form.Control
                                                    required
                                                    type="text"
                                                    name="lastname"
                                                    placeholder="Enter Last Name"
                                                    onChange={handleChangeContactPartner}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <Form.Group className="mx-3" controlId="formBasicTitle">
                                                <Form.Label
                                                    className="form-view-label"
                                                    htmlFor="formBasicTitle"
                                                >
                                                    Phone
                                                </Form.Label>
                                                <Form.Control
                                                    style={{ height: "36px" }}
                                                    required
                                                    type="phone"
                                                    minLength="10"
                                                    maxLength="10"
                                                    name="phone"
                                                    placeholder="Enter Phone"
                                                    onKeyDownCapture={(e) => {
                                                        (e?.key == " " ||
                                                            (e.key.length == 1 && isNaN(e.key))) &&
                                                            e.preventDefault();
                                                    }}
                                                    onChange={handleChangeContactPartner}
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={6}>
                                            <Form.Group className="mx-3">
                                                <Form.Label
                                                    className="form-view-label"
                                                    htmlFor="formBasicCompany"
                                                >
                                                    Area
                                                </Form.Label>
                                                <Form.Select
                                                    style={{ height: "36px" }}
                                                    required
                                                    name="area"
                                                    onChange={handleChangeContactPartner}
                                                >
                                                    <option value="">--Select Area--</option>
                                                    <option value="VKI & Sikar road">
                                                        VKI & Sikar road
                                                    </option>
                                                    <option value="Vidyadhar nagar">
                                                        Vidyadhar nagar
                                                    </option>
                                                    <option value="Bani park">Bani park</option>
                                                    <option value="Vaishali Nagar">Vaishali Nagar</option>
                                                    <option value="Vaishali Nagar-West">
                                                        Vaishali Nagar-West
                                                    </option>
                                                    <option value="Mansarovar">Mansarovar</option>
                                                    <option value="Patrakar colony">
                                                        Patrakar colony
                                                    </option>
                                                    <option value="Sanganer">Sanganer</option>
                                                    <option value="Gopalpura bypass">
                                                        Gopalpura bypass
                                                    </option>
                                                    <option value="Shyam nagar/Nirman nagar">
                                                        Shyam nagar/Nirman nagar
                                                    </option>
                                                    <option value="Durgapura">Durgapura</option>
                                                    <option value="JLN Marg">JLN Marg</option>
                                                    <option value="Malviya Nagar">Malviya Nagar</option>
                                                    <option value="Bapu Nagar-Lal kothi">
                                                        Bapu Nagar-Lal kothi
                                                    </option>
                                                    <option value="Jagatpura">Jagatpura</option>
                                                    <option value="C-Scheme-Civil lines">
                                                        C-Scheme-Civil lines
                                                    </option>
                                                    <option value="Jawahar nagar-Raja park">
                                                        Jawahar nagar-Raja park
                                                    </option>
                                                    <option value="Old city">Old city</option>
                                                    <option value="Amer-Delhi road">
                                                        Amer-Delhi road
                                                    </option>
                                                    <option value="Jhotwara">Jhotwara</option>
                                                    <option value="Other">Other</option>
                                                </Form.Select>
                                                <Form.Control.Feedback type="invalid">
                                                    Please provide area.
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleSubmitContact}>
                        Save
                    </Button>
                    <Button onClick={handleContactModelClose} variant="light">
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* //End Here By Farhan Khan on 06-Nov-2024 */}
        </Container>
    )
}
export default LeadEdit