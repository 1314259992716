import React, { useState, useEffect } from "react";
import { Badge, Button, Card, Col, Container, Nav, Row } from "react-bootstrap";
import Confirm from "./Confirm";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import FilesCreate from "./FilesCreate";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import RelatedListFiles from "./RelatedListFiles";
import RealetedLeadTest from "./RealetedLeadTest";
import RelatedListTask from "./RelatedListTask";
import TaskEdit from "./TaskEdit";
import { Link } from "react-router-dom";
import RelatedListBusiness from "./RelatedListBusiness";
import helper from "./common/helper";
import jwt_decode from "jwt-decode";
import * as constants from '../constants/CONSTANT';
import moment from "moment";
// New Change : Import new Component
import RelatedListReferralLeads from "./RelatedListReferralLeads";

const ContactView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const contact = location.state;
  const [contact, setContact] = useState(location.state ? location.state : {});
  const [relatedListPatientTests, setRelatedListPatientTests] = useState(true);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [relatedListLeadtTests, setRelatedListLeadtTests] = useState(true);
  const [modalShowMedicaltestitem, setModalShowMedicaltestitem] = useState(false);
  const [refreshBusinessList, setRefreshBusinessList] = useState();
  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [reletedListTask, setReletedListTask] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [reletedListBusiness, setRelatedListBusiness] = useState(false);
  // New Change : added a new State for referral leads
  const [relatedListReferralLeads, setRelatedListReferralLeads] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountdata, setAccountData] = useState({});
  const [refreshTaskList, setRefreshTaskList] = useState();
  const [userInfo, setUserInfo] = useState({});
  const [modalShow, setModalShow] = useState(false);
  //////("contact=>" + JSON.stringify(contact));

  useEffect(() => {
    setUserInfo(jwt_decode(localStorage.getItem('token')));
    fetchContact();

  }, []);

  const submitTasks = () => {
    setModalShowTask(false);
    setRefreshTaskList(Date.now());
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setReletedListTask(true);
      setRelatedListFiles(false);
      setRelatedListBusiness(false);
      setRelatedListReferralLeads(false);
    } else if (key === "files") {
      setRelatedListFiles(true);
      setReletedListTask(false);
      setRelatedListBusiness(false);
      setRelatedListReferralLeads(false);
    } else if (key === "business") {
      setRelatedListBusiness(true);
      setReletedListTask(false);
      setRelatedListFiles(false);
      setRelatedListReferralLeads(false);
    }
    // New Change : for referral lead
    else if (key === "referral_leads") {
      setRelatedListBusiness(false);
      setReletedListTask(false);
      setRelatedListFiles(false);
      setRelatedListReferralLeads(true);
    }
  };

  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setModalShowTask(false);
  };

  const fetchContact = () => {
    // Coming from Email
    if (!contact.id && location.hasOwnProperty("pathname")) {
      contact.id = location.pathname.split("/")[2];
      ////("pathnam===" + contact.id);
    }
    async function initContact() {
      let result = await SparkApi.fetchContact(contact.id);
      ////('result:', result);
      if (result) {
        setContact(result);
      } else {
        setContact({});
      }
    }
    initContact();
  };

  const deleteContact = async () => {
    const result = await SparkApi.deleteContact(contact.id);
    if (result.success) navigate(`/contacts`);
  };

  const editContact = () => {
    navigate(`/contacts/${contact.id}/e`, { state: contact });
  };

  const [currentRec, setCurrentRecord] = useState({});
  const [locationRecordv1, setLocationRecordv1] = useState({});
  const [latitudev1, setLatitudev1] = useState(null);
  const [longitudev1, setLongitudev1] = useState(null);
  const [addressv1, setAddressv1] = useState(`${contact.street} ${contact.state} ${contact.zipcode}`); // Default address
  const [buttonDisabledv1, setButtonDisabledv1] = useState(false);
  const [remarksv1, setRemarksv1] = useState('');

  useEffect(() => {
    async function init() {
      let userdata = await SparkApi.getCurrentUserTrackingRec();
      setCurrentRecord(userdata);
      setLocationRecordv1(userdata);
      if (userdata.logindatetime != null && userdata.logoutdatetime == null) {
        setButtonDisabledv1(true);
        setRemarksv1(userdata.remarks);
      } else if (userdata.logindatetime.length && userdata.logoutdatetime.length) {
        setRemarksv1(userdata.remarks);
        setButtonDisabledv1(false);
      }
    }
    init();

    if (!window.myMap) {
      window.myMap = myMapFunction;
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=myMap`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
    } else {
      myMapFunction();
    }

    setCurrentRecord({ errors: 'No Data' });
  }, []);

  const myMapFunction = async () => {
    if (!latitudev1 || !longitudev1) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: addressv1 }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          setLatitudev1(location.lat());
          setLongitudev1(location.lng());

          const mapProp = {
            center: location,
            zoom: 14,
          };

          const map = new window.google.maps.Map(document.getElementById("googleMapaddress"), mapProp);

          const marker = new window.google.maps.Marker({
            map,
            position: location,
            title: results[0].formatted_address,
          });

          const infowindow = new window.google.maps.InfoWindow({
            content: results[0].formatted_address,
          });

          marker.addListener("click", () => {
            infowindow.open(map, marker);
          });

          setAddressv1(results[0].formatted_address);
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    } else {
      const location = new window.google.maps.LatLng(latitudev1, longitudev1);
      const mapProp = {
        center: location,
        zoom: 14,
      };

      const map = new window.google.maps.Map(document.getElementById("googleMapaddress"), mapProp);

      const marker = new window.google.maps.Marker({
        map,
        position: location,
        title: addressv1,
      });

      const infowindow = new window.google.maps.InfoWindow({
        content: addressv1,
      });

      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });
    }
  };

  return (
    <div>
      {contact && (
        <Container>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteContact={deleteContact}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="contact"
            />
          )}
          <Row className="view-form">

            <Col lg={11} className="pb-3 pt-2">
              <Link className="nav-link" to="/contacts">
                Home <i className="fa-solid fa-chevron-right"></i> <div style={{ color: "#23468c", display: "inline" }}>Contacts</div>
              </Link>
            </Col>
            <Col></Col>
          </Row>
          <Row className="view-form gx-5 mx-auto">

            <Col lg={8}>
              <Row className="view-form-header align-items-center">
                <Col lg={8}>
                  Contact
                  <h5>
                    {contact.salutation} {contact.firstname} {contact.lastname}
                  </h5>
                </Col>
                <Col lg={4} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editContact(true)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>
                  {userInfo && userInfo?.userrole === 'SUPER_ADMIN' &&
                    helper.checkPermission(constants.MODIFY_ALL) && <Button
                      className="btn-sm"
                      variant="danger"
                      onClick={() => setModalShow(true)}
                    >
                      Delete
                    </Button>}
                </Col>
              </Row>
              <Row className="py-3 ibs-edit-form">
                <Col lg={12}>
                  <label>Company Name</label>
                  <Link to={"/accounts/" + contact.accountid} state={{ id: contact.accountid }}>
                    <span>{contact.accountname}</span>
                  </Link>
                </Col>
                <Col lg={6}>
                  <label>Name</label>
                  <span>
                    {contact.salutation} {contact.firstname} {contact.lastname}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>Email</label>
                  <span>{contact.email}</span>
                </Col>
                <Col lg={6}>
                  <label>Phone</label>
                  <span>{contact.phone}</span>
                </Col>
                <Col lg={6}>
                  <label>Contact Type</label>
                  <span>{contact.title}</span>
                </Col>
                {contact.title === 'Other' && <Col lg={6}>
                  <label>Other Type</label>
                  <span>{contact.othertitle}</span>
                </Col>}

                <Col lg={6}>
                  <label>City</label>
                  <span>{contact.city}</span>
                </Col>
                <Col lg={6}>
                  <label>State</label>
                  <span>{contact.state}</span>
                </Col>
                <Col lg={6}>
                  <label>Pincode</label>
                  <span>{contact.pincode}</span>
                </Col>
                <Col lg={6}>
                  <label>Country</label>
                  <span>{contact.country}</span>
                </Col>
                <Col lg={6}>
                  <label>Street</label>
                  <span>{contact.street}</span>
                </Col>
                <Col lg={6}>
                  <label>Area</label>
                  <span>{contact.area}</span>
                </Col>
                {contact.area === 'Other' && <Col lg={6}>
                  <label>Other Area</label>
                  <span>{contact.otherarea}</span>
                </Col>}
                <Col lg={6}>
                  <label>Date Of Birth</label>
                  <span>{contact.dob ? moment(contact.dob).format('DD-MM-YYYY') : ""}</span>
                </Col>
                <Col lg={6}>
                  <label>Anniversary</label>
                  <span>{contact.anniversary ? moment(contact.anniversary).format('DD-MM-YYYY') : ""}</span>
                </Col>
                <Col lg={6}>
                  <label>Created By</label>
                  <span><Badge
                    bg="warning"
                    className="text-dark"
                    style={{ display: "inline" }}
                  >{contact.createdbyname}</Badge></span>
                </Col>
                <Col lg={6}>
                  <label>Assigned User</label>
                  <span>
                    {contact?.ownername && contact?.ownername.split(', ').map((name, index) => (
                      <React.Fragment key={index}>
                        <Badge
                          bg="warning"
                          className="mx-1"
                          style={{ display: "inline", color: "#000" }}
                        >
                          {name}<br />
                        </Badge>
                      </React.Fragment>
                    ))}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col lg={4}>
              <div id="googleMapaddress" className="my-4" style={{ width: '100%', height: '300px', border: '1px solid black' }}>

              </div>
            </Col>
          </Row>
          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="files"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                <Tab eventKey="files" title="Files"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
                <Tab eventKey="business" title="Business"></Tab>
                {/* New Change : added new Tab for referral leads */}
                <Tab eventKey="referral_leads" title="Referral Leads"></Tab>
              </Tabs>

              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  New File
                </Button>
              )}
              {modalShowFile && (
                <FilesCreate
                  show={modalShowFile}
                  onHide={() => setModalShowFile(false)}
                  parent={contact}
                  table="medicaltest"
                  submitFiles={submitFiles}
                />
              )}

              {reletedListTask && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowTask(true)}
                >
                  New Task
                </Button>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={contact.id}
                  parenttable={"contact"}
                  table="account"

                  submitTasks={submitTasks}
                />
              )}
            </Card.Header>
            <Card.Body>
              {/* {relatedListPatientTests && <RelatedListPatientTests parent={contact}/>} */}

              {relatedListFiles && (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={contact}
                />
              )}
              {reletedListTask ? <RelatedListTask refreshTaskList={refreshTaskList} parent={contact} /> : ""}
              {reletedListBusiness ? <RelatedListBusiness refreshTaskList={refreshBusinessList} parent={contact} /> : ""}
              {/* New Change : added new component for referral leads */}
              {relatedListReferralLeads && <RelatedListReferralLeads parent={contact}/>}
            </Card.Body>
          </Card>
        </Container>
      )}
    </div>
  );
};
export default ContactView;
