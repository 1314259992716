import React, { useState, useEffect } from "react";
import { Badge, Button, Card, Col, Container, Form, Modal, Row, Spinner } from "react-bootstrap";
import Confirm from "./Confirm";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import SparkApi from "../api/SparkApi";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import FilesCreate from "./FilesCreate";
import RelatedListFiles from "./RelatedListFiles";
import RealetedLeadTest from "./RealetedLeadTest";
import RelatedListTask from "./RelatedListTask";
import TaskEdit from "./TaskEdit";
import Path from "./common/Path";
import moment from "moment";
import * as constants from "../constants/CONSTANT";
import PubSub from "pubsub-js";
import EmailComposer from "./common/EmailComposer";
import Chat from "./common/Chat";
import jwt_decode from "jwt-decode";
import RelatedListLocationHistory from "./RelatedListLocationHistory";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const LeadView = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  //const course = location.state;
  const [lead, setLead] = useState(location.state ? location.state : {});
  ////console.log('lead', lead)
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [relatedListFiles, setRelatedListFiles] = useState(true);
  const [releatedlistlocation, setreleatedlistlocation] = useState(false);

  const [relatedListLeadtTests, setRelatedListLeadtTests] = useState(true);
  const [validated, setValidated] = useState(false);
  const [modalShowFile, setModalShowFile] = useState(false);
  const [modalShowTask, setModalShowTask] = useState(false);
  const [modalShowEmail, setModalShowEmail] = useState(false);
  const [reletedListTask, setReletedListTask] = useState(false);
  const [refreshFileList, setRefreshFileList] = useState();
  const [userName, setUserName] = useState("");
  const [refreshTaskList, setRefreshTaskList] = useState(Date.now());
  const [convertShow, setConvertShow] = useState(true);
  const [convertSalesAmount, setconvertSalesAmount] = useState(false);

  const [messages, setMessages] = useState([
    {
      from: "Rajesh",
      date: "23 Feb, 2023",
      message: "This is a great deal. Why you not looked into this."
    },
    {
      from: "Rajesh",
      date: "23 Feb, 2023",
      message: "This is a great deal. Why you not looked into this."
    },
    {
      from: "Rajesh",
      date: "23 Feb, 2023",
      message: "This is a great deal. Why you not looked into this."
    }
  ])
  const [leadStatusArray, setleadStatusArray] = useState(
    JSON.parse(localStorage.getItem("lead_status"))
  );
  const [userInfo, setUserInfo] = useState({});
  // Ensure lead.ownerNames is a string and split it into an array of owner names
  const ownerNames = lead && lead?.ownername ? lead?.ownername.split(', ') : [];

  useEffect(() => {
    setUserInfo(jwt_decode(localStorage.getItem('token')));
    fetchLead();


    //init();
  }, []);

  //Bug fix : setLead Issue(when user open lead view page with only lead id in state the least information he can't see about lead)
  useEffect(() => {
    if (lead.id) {
      // console.log("Lead in view page", lead);
    }
  }, [lead]);

  const fetchLead = () => {
    if (
      !lead.id &&
      location.hasOwnProperty("pathname") &&
      location.pathname.split("/").length >= 3
    ) {
      lead.id = location.pathname.split("/")[2];
    }

    async function initStudent() {
      let result = await SparkApi.fetchLeadById(lead.id);

      if (
        result?.convertedcontactid &&
        result?.contactname &&
        result.contactname.trim().length > 0
      ) {
        ////console.log('convert call');
        setConvertShow(false);
      }
      if (result) {
        ////console.log("=======", result);

        setLead(result);
        setData(result);
        ////console.log("name : ", lead.firstname);
      } else {
        setLead({});
      }
    }
    initStudent();
  };
  const deleteLead = async () => {
    const result = await SparkApi.deleteLead(lead.id);
    if (result.success) navigate(`/leads`);
    ////console.log("result.success : ", result.success);
  };
  const editLead = () => {
    navigate(`/leads/${lead.id}/e`, { state: lead });
  };

  const handleSelect = (key) => {
    if (key === "tasks") {
      setReletedListTask(true);
      setRelatedListFiles(false);
      setreleatedlistlocation(false)
    } else if (key === "files") {
      setRelatedListFiles(true);
      setReletedListTask(false);
      setreleatedlistlocation(false)
    } else if (key === "Location History") {
      setreleatedlistlocation(true)
      setReletedListTask(false);
      setRelatedListFiles(false);

    }
  };

  const submitFiles = () => {
    setModalShowFile(false);
    setRefreshFileList(Date.now());
    setModalShowTask(false);
  };

  const handleConvert = async () => {

    let convertStatus = "";

    let status = leadStatusArray.filter((item) => item.is_converted === true);
    console.log("status:", status);
    convertStatus = status[0].label;
    const result = await SparkApi.convertLeadToContact(lead.id, convertStatus);
    ////console.log('result', result)
    if (result) {
      let contactId = result.convertedcontactid;
      PubSub.publish("RECORD_SAVED_TOAST", {
        title: "Lead Convert ",
        message: "Record Convert successfully",
      });
      navigate(`/contacts/${contactId}`);
      ////console.log("result.success : ", result.success);
    }
  };

  const submitTasks = () => {
    setModalShowTask(false);
    setModalShowEmail(false);
    setRefreshTaskList(Date.now());
  };


  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [remarks, setRemarks] = useState();
  const [result, setResult] = useState()
  const [buttonDisabled, setButtonDisabled] = useState(false)
  const [locationRecord, setLocationRecord] = useState({});
  const [currentrecord, setCurrentrecord] = useState();
  const [spinner, setSpinner] = useState(false);
  const [alert, setAlert] = useState(null);
  const [address, setAddress] = useState()
  const [isSpinner, setIsSpinner] = useState(false);
  useEffect(() => {
    if (alert) {
      setTimeout(() => {
      }, 3000)
    }
  }, [alert])


  useEffect(() => {
    async function init() {
      let userdata = await SparkApi.getCurrentUserTrackingRec();
      ////console.log('userdata:', userdata);
      setCurrentrecord(userdata);
      setLocationRecord(userdata);
      if (userdata?.logindatetime != null && userdata?.logoutdatetime == null) {
        setButtonDisabled(true)
        setRemarks(userdata.remarks)
      } else if (userdata?.logindatetime?.length && userdata?.logoutdatetime?.length) {
        setRemarks(userdata.remarks)
        setButtonDisabled(false)
      }
    }
    init();

    //setSpinner(true);
    if (!window.myMap) {
      ////console.log('!window.myMap')       
      window.myMap = myMap;
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyDkVza2FtLItgn_kzJ27_A4l2Eyf3ZrOOI&callback=myMap`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
      //////console.log('refresh:');
    } else {
      ////console.log('window.myMap')      
      myMap();
    }

    setCurrentrecord({ errors: 'No Data' });
    ////console.log('currentRec => ', currentRec);
  }, []);


  const myMap = async () => {
    ////console.log('calling my map=====')



    var mapProp = {
      center: new window.google.maps.LatLng(latitude ? latitude : 12, longitude ? longitude : 12),
      zoom: 14,
    };

    const locate = window.navigator && window.navigator.geolocation;
    const currentCoords = { lat: 0, lng: 0 };

    if (locate) {
      ////console.log('in locate==>')
      locate.getCurrentPosition((position) => {
        ////console.log('in position', position)
        currentCoords.lat = position.coords.latitude;
        currentCoords.lng = position.coords.longitude;
        setLatitude(position.coords.latitude);
        setLongitude(position.coords.longitude);

        // setSpinner(false)
        mapProp = {
          center: new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude),
          zoom: 14,
        }

        //  var map = new window.google.maps.Map(document.getElementById("googleMap"), mapProp);
        ////console.log('location => ',location)
        var geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ location: currentCoords }, function (results, status) {
          ////console.log(results)
          let cur_address = results && results.length > 0 ? results[0].formatted_address : '';
          setAddress(cur_address);
          var marker = new window.google.maps.Marker({
            //map: map,
            position: { lat: currentCoords.lat, lng: currentCoords.lng },
            title: cur_address,
            content: cur_address,
          });

          var infowindow = new window.google.maps.InfoWindow({
            content: cur_address
          });
          marker.addListener("click", () => {
            infowindow.open({
              anchor: marker,
              //   map,
            });
          });

        });

      }, (error) => {
        ////console.log('error in location -> ', error) 
      }, { maximumAge: 10000, timeout: 5000, enableHighAccuracy: true }
      )
    }

    setTimeout(() => {


    }, 500)

  }



  const setLatLongJs = async (checkIn) => {
    const locate = window.navigator && window.navigator.geolocation;
    if (locate) {
      locate.getCurrentPosition(async (position) => {
        if (checkIn) {
          let res = await SparkApi.createCheckInRecord({ 'logindatetime': new Date(), location: address, loginlattitude: position.coords.latitude.toString(), loginlongitude: position.coords.longitude.toString(), attandancetype: 'Lead Attendance', leadid: lead.id });
          setTimeout(() => {
            if (res) {
              setButtonDisabled(true);
              setLocationRecord(res);
              setRefreshTaskList(Date.now());
              PubSub.publish('RECORD_SAVED_TOAST', { title: 'Successfully Check-in', message: 'Check-in successfully' });
            } else {
              setAlert({ message: 'Somthing Went Wrong.', type: 'danger' });
            }
          }, '500')
        }

      }, (error) => {

      })
    }
  }

  const handleCheckIn = async () => {
    setLocationRecord({});
    setLatLongJs(true);
    setButtonDisabled(true);
  }

  //Check-Out Added by Farhan Khan 18-Oct-2024.
  const handleLocation = async () => {
    // fetchCheckInData();
    const locate = window.navigator && window.navigator.geolocation;
    locate.getCurrentPosition(async (position) => {
      const res = await SparkApi.handlCheckOut({
        id: locationRecord.id,
        logoutdatetime: new Date(),
        logoutlattitude: position.coords.latitude.toString(),
        logoutlongitude: position.coords.longitude.toString(),
        remarks,
      });
      if (res) {
        setButtonDisabled(false);
        setRemarks('');
        // New Change : set the state for refresh the related list location history for fetch current updated records 5 Nov 
        setRefreshTaskList(Date.now());
      } else {
        setAlert({ message: 'Something Went Wrong.', type: 'danger' });
      }
    })
  };

  // async handleCheckInData = (e) => {

  // }


  const [SalesAmount, setsetSalesAmount] = useState({ amount: "", fulladvance: "", percentageofadvance: "", valueofadvance: "" });
  //console.log('lead', lead)
  const handleSalesAmountCahnge = (e) => {
    setsetSalesAmount({ ...SalesAmount, [e.target.name]: e.target.value })
  }

  const checkRequredFields = () => {

    //console.log(eventRec.title);
    //console.log(eventRec.startdatetime);
    //console.log(eventRec.enddatetime);
    //console.log(eventRec.status);
    //console.log(eventRec.priority);
    console.log(SalesAmount);
    // Made require only sales amount and  fulladvance fields 5 Sept @ali
    if ((SalesAmount?.amount && SalesAmount.amount.trim() !== '') &&
      (SalesAmount?.fulladvance && SalesAmount.fulladvance.trim() !== '')) {
      return false;
    }
    return true;
  }


  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (checkRequredFields()) {
      console.log('sdafdsf')
      setValidated(true);
      return;
    }

    setIsSpinner(true);

    if (SalesAmount.amount) {
      lead.amount = SalesAmount.amount
      lead.fulladvance = SalesAmount.fulladvance
      lead.percentageofadvance = SalesAmount.percentageofadvance
      lead.valueofadvance = SalesAmount.valueofadvance
      let result = await SparkApi.updateLead(lead);
      console.log('result', result)
      handleConvert();

    } else {
      window.alert('Sales Amount is Required')
    }
    // setIsSpinner(false);

  }

  const [currentRec, setCurrentRecord] = useState({});
  const [locationRecordv1, setLocationRecordv1] = useState({});
  const [latitudev1, setLatitudev1] = useState(null);
  const [longitudev1, setLongitudev1] = useState(null);
  const [addressv1, setAddressv1] = useState(`${lead.street} ${lead.state} ${lead.zipcode}`); // Default address
  const [buttonDisabledv1, setButtonDisabledv1] = useState(false);
  const [remarksv1, setRemarksv1] = useState('');

  useEffect(() => {
    async function init() {
      let userdata = await SparkApi.getCurrentUserTrackingRec();
      setCurrentRecord(userdata);
      setLocationRecordv1(userdata);
      if (userdata?.logindatetime != null && userdata?.logoutdatetime == null) {
        setButtonDisabledv1(true);
        setRemarksv1(userdata.remarks);
      } else if (userdata?.logindatetime?.length && userdata?.logoutdatetime?.length) {
        setRemarksv1(userdata.remarks);
        setButtonDisabledv1(false);
      }
    }
    init();

    if (!window.myMap) {
      window.myMap = myMapFunction;
      const googleMapScript = document.createElement('script');
      googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&callback=myMap`;
      googleMapScript.async = true;
      window.document.body.appendChild(googleMapScript);
    } else {
      myMapFunction();
    }

    setCurrentRecord({ errors: 'No Data' });
  }, []);

  const myMapFunction = async () => {
    if (!latitudev1 || !longitudev1) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address: addressv1 }, (results, status) => {
        if (status === 'OK') {
          const location = results[0].geometry.location;
          setLatitudev1(location.lat());
          setLongitudev1(location.lng());

          const mapProp = {
            center: location,
            zoom: 14,
          };

          const map = new window.google.maps.Map(document.getElementById("googleMapaddress"), mapProp);

          const marker = new window.google.maps.Marker({
            map,
            position: location,
            title: results[0].formatted_address,
          });

          const infowindow = new window.google.maps.InfoWindow({
            content: results[0].formatted_address,
          });

          marker.addListener("click", () => {
            infowindow.open(map, marker);
          });

          setAddressv1(results[0].formatted_address);
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      });
    } else {
      const location = new window.google.maps.LatLng(latitudev1, longitudev1);
      const mapProp = {
        center: location,
        zoom: 14,
      };

      const map = new window.google.maps.Map(document.getElementById("googleMapaddress"), mapProp);

      const marker = new window.google.maps.Marker({
        map,
        position: location,
        title: addressv1,
      });

      const infowindow = new window.google.maps.InfoWindow({
        content: addressv1,
      });

      marker.addListener("click", () => {
        infowindow.open(map, marker);
      });
    }
  };

  // const setLatLongJs = async (checkIn) => {
  //   const locate = window.navigator && window.navigator.geolocation;
  //   if (locate) {
  //     locate.getCurrentPosition(async (position) => {
  //       if (checkIn) {
  //         let res = await SparkApi.createCheckInRecord({
  //           logindatetime: new Date(),
  //           location: addressv1,
  //           loginlatitude: position.coords.latitude.toString(),
  //           loginlongitude: position.coords.longitude.toString(),
  //           attandancetype: 'Lead Attandance',
  //           leadid: lead.id
  //         });
  //         setTimeout(() => {
  //           if (res) {
  //             setButtonDisabledv1(true);
  //             setLocationRecordv1(res);
  //             setRefreshTaskList(Date.now());
  //             PubSub.publish('RECORD_SAVED_TOAST', { title: 'Attandance Created', message: 'Attandance created successfully' });
  //           } else {
  //             setAlert({ message: 'Something Went Wrong.', type: 'danger' });
  //           }
  //         }, 500);
  //       }
  //     }, (error) => {
  //       console.error('Error in location:', error);
  //     });
  //   }
  // };





  return (
    <Container>

      {lead && (
        <>
          {modalShow && (
            <Confirm
              show={modalShow}
              onHide={() => setModalShow(false)}
              deleteLead={deleteLead}
              title="Confirm delete?"
              message="You are going to delete the record. Are you sure?"
              table="lead"
            />
          )}
          <Row className="view-form">
            <Col lg={11} className="pb-3 pt-2">
              <Link className="nav-link" to="/leads">
                Home <i className="fa-solid fa-chevron-right"></i>{" "}
                <div style={{ color: "#23468c", display: "inline" }}>Leads</div>
              </Link>
            </Col>
            <Col></Col>
          </Row>

          <Row className="view-form gx-5 mx-auto">
            <Col lg={8}>
              <Row className="view-form-header align-items-center">
                <Col lg={4}>
                  Lead
                  <h5>
                    {/* New Change : Fixed the issue of lead name */}
                    {`${lead?.salutation || ""} ${lead?.firstname || ""} ${lead?.lastname || ""}`}
                  </h5>

                </Col>
                <Col lg={8} className="d-flex justify-content-end">
                  <Button
                    className="btn-sm mx-2"
                    onClick={() => editLead(true)}
                  >
                    <i className="fa-regular fa-pen-to-square"></i>
                  </Button>

                  {userInfo && userInfo?.userrole === 'SUPER_ADMIN' && <Button
                    className="btn-sm mx-2"
                    variant="danger"
                    onClick={() => setModalShow(true)}
                  >
                    Delete
                  </Button>}
                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowEmail(true)}
                    variant="success"
                    title="Send Email"
                  >
                    <i class="fa-regular fa-envelope mx-2"></i>
                  </Button>

                  <Button
                    className="btn-sm mx-2"
                    variant="warning"
                    onClick={() => handleCheckIn()}
                    disabled={buttonDisabled}
                  >
                    Check In
                  </Button>

                  <Button
                    className="ml-2"
                    disabled={!buttonDisabled}
                    variant="danger"
                    onClick={() => handleLocation(false)}
                    style={{ marginLeft: "6px" }}
                  >
                    Check-Out
                  </Button>
                  {convertShow === true ? (
                    <Button
                      className="btn-sm mx-2"
                      variant="warning"
                      onClick={() => setconvertSalesAmount(true)}
                    >
                      Convert
                    </Button>
                  ) : (
                    ""
                  )}
                </Col>
              </Row>

              <Row className="py-3 ibs-edit-form">
                <Col lg={12}>
                  {/* //Bug fix : setLead Issue(when user open lead view page with only lead id in state the least information he can't see about lead) solved this issue when the all lead data fetch succesfully(i.e we get lead status)  then re render the component(using conditional rendering) */}
                  {lead?.leadstatus && <Path
                    values={leadStatusArray}
                    selectedValue={lead?.leadstatus && lead.leadstatus}
                  />}
                </Col>

                <Col lg={6}>
                  <label>Lead Name</label>
                  <span>
                    {/* New Change : Fixed the issue of lead name */}
                    {`${lead?.salutation || ""} ${lead?.firstname || ""} ${lead?.lastname || ""}`}
                  </span>
                </Col>

                
                {/* New change : added new field follow_up date */}
                <Col lg={6}>
                  <label>Follow Up Date</label>
                  <span>{lead?.follow_up && moment(lead?.follow_up).format("DD-MM-YYYY")}</span>
                </Col>
                
                <Col lg={6}>
                  <label>Phone</label>
                  <span>{lead.phone}</span>
                </Col>

                {/*  New Change : added new some new fields regarding to phone numbers 18 Oct 2024 */}
                <Col lg={6}>
                  <label>Whatsapp</label>
                  <span>{lead.whats_no}</span>
                </Col>
                <Col lg={6}>
                  <label>Other Phone</label>
                  <span>{lead.other_phone}</span>
                </Col>
                {/* ------------------------------------------------------- */}

                <Col lg={6}>
                  <label>Email</label>
                  <span>{lead.email}</span>
                </Col>
                <Col lg={6}>
                  <label>Account Name (New Account)</label>
                  <span>{lead.company}</span>
                </Col>
                <Col lg={6}>
                  <label>Account Name (Existing Account)</label>
                  <span>{lead.account_name}</span>
                </Col>
                {/* <Col lg={6}>
                  <label>Account Name (Company)</label>
                  {lead.accountid ? <span>{lead.account_name}</span> : <span>{lead.company}</span>}
                </Col> */}
                <Col lg={6}>
                  <label>Title</label>
                  <span>{lead.title}</span>
                </Col>
                {/* <Col lg={6}>
                  <label>Fax</label>
                  <span>{lead.fax}</span>
                </Col> */}
                <Col lg={6}>
                  <label>Lead Source</label>
                  <span>{lead.leadsource}</span>
                </Col>
                {lead.leadsource === 'Other' &&
                  <Col lg={6}>
                    <label>Other Source</label>
                    <span>{lead.othersource}</span>
                  </Col>
                }
                <Col lg={6}>
                  <label>Lead Type</label>
                  <span>{lead.leadtype}</span>
                </Col>

                <Col lg={6}>
                  <label>Area</label>
                  <span>{lead.area}</span>
                </Col>
                {lead?.area === 'Other' &&
                  <Col lg={6}>
                    <label>Other Area</label>
                    <span>{lead.otherarea}</span>
                  </Col>
                }
                {/* <Col lg={6}>
                  <label>User Name</label>
                  <span>{userName}</span>
                </Col> */}

                <Col lg={6}>
                  <label>Business Type</label>
                  <span>{lead.industry}</span>
                </Col>

                <Col lg={6}>
                  <label>Sales Amount</label>
                  <span>₹ {lead.amount}</span>
                </Col>
                <Col lg={6}>
                  <label>Expected Amount</label>
                  <span>₹ {lead.expectedamount}</span>
                </Col>
                {/* <Col lg={6}>
                  <label>Payment Model</label>
                  <span>{lead.paymentmodel}</span>
                </Col>
                <Col lg={6}>
                  <label>Payment Terms</label>
                  <span>{lead.paymentterms}</span>
                </Col> */}
                <Col lg={6}>
                  <label>Status</label>
                  <span>{lead.leadstatus}</span>
                </Col>


                <Col lg={6}>
                  <label>Date Of Birth</label>
                  <span>{lead.dob ? moment(lead.dob).format('DD-MM-YYYY') : ""}</span>
                </Col>
                <Col lg={6}>
                  <label>Anniversary</label>
                  <span>{lead.anniversary ? moment(lead.anniversary).format('DD-MM-YYYY') : ""}</span>
                </Col>
                {lead.iswon === true && (
                  <Col lg={6}>
                    <label>Converted Contact</label>
                    <span>
                      <Link to={"/contacts/" + lead.convertedcontactid}>
                        {lead.contactname}
                      </Link>
                    </span>
                  </Col>
                )}
                {lead.iswon === false && (
                  <Col lg={6}>
                    <label>Lost Reason</label>
                    <span>{lead.lostreason}</span>
                  </Col>
                )}
                <Col lg={6}>
                  <label>Assigned User</label>
                  <span>
                    {ownerNames.map((name, index) => (
                      <React.Fragment key={index}>
                        <Badge
                          bg="warning"
                          className="mx-2"
                          style={{ display: "inline", color: "#000" }}
                        >
                          {name}<br />
                        </Badge>
                      </React.Fragment>
                    ))}
                  </span>
                </Col>
                {/* <Col lg={6}>
                  <label>User Type</label>
                  <span>
                    {lead.usertype}
                  </span>
                </Col> */}

                <Col lg={6}>
                  <label>Product Name</label>
                  <span>
                    {lead.product}
                  </span>
                </Col>
                <Col lg={12}>
                  <label>Description</label>
                  <span>{lead.description}</span>
                </Col>
                <Col lg={12}>
                  <label>Created Date</label>
                  <span>{moment(lead.createddate).format("DD-MM-YYYY")}</span>
                </Col>
                {/* New Chnage : Enhanced the UI & added new filedds according to new Approch for partenr referral */}
                <Col lg={12} className="section-header">
                  PARTNERS REFERRAL INFORMATION
                </Col>
                <Col lg={6}>
                  <label>First Name</label>
                  <Link to={`/contacts/${lead.partner_ref_id}`}>
                    <span>{lead.partner_referral_first_name}</span>
                  </Link>
                </Col>
                <Col lg={6}>
                  <label>Last Name</label>
                  <span>{lead.partner_referral_last_name}</span>
                </Col>
                <Col lg={6}>
                  <label>Phone</label>
                  <span>{lead.partner_referral_phone}</span>
                </Col>
                {/* <Col lg={6}>
                  <label>Conatct (Architect)</label>
                  <span>{lead.partner_refral_name}</span>
                </Col> */}
                <Col lg={12} className="section-header">
                  ADDRESS INFORMATION
                </Col>
                <Col lg={6}>
                  <label>Address Street</label>
                  <span>{lead.street}</span>
                </Col>
                <Col lg={6}>
                  <label>City</label>
                  <span>
                    {lead.city !== null ? lead.city : ""} {lead.zipcode}
                  </span>
                </Col>
                <Col lg={6}>
                  <label>State</label>
                  <span>{lead.state}</span>
                </Col>
                <Col lg={6}>
                  <label>Country</label>
                  <span>{lead.country}</span>
                </Col>

                <Col className="mt-5"></Col>
              </Row>
            </Col>
            <Col lg={4}>

              <Chat parentid={lead.id} />
              <div id="googleMapaddress" className="my-4" style={{ width: '100%', height: '300px', border: '1px solid black' }}>

              </div>
            </Col>
          </Row>

          <Card bg="light" text="light" className="mb-2 mt-4">
            <Card.Header className="d-flex justify-content-between">
              <Tabs
                defaultActiveKey="files"
                id="uncontrolled-tab-example"
                onSelect={(key) => handleSelect(key)}
              >
                {/* <Tab eventKey="patienttests" title="Medical Tests"></Tab> */}
                <Tab eventKey="files" title="Files"></Tab>
                <Tab eventKey="tasks" title="Tasks"></Tab>
                <Tab eventKey="Location History" title="Location History"></Tab>

              </Tabs>

              {relatedListFiles && (
                <Button
                  className="float-right btn-sm"
                  onClick={() => setModalShowFile(true)}
                >
                  New File
                </Button>
              )}
              {modalShowFile && (
                <FilesCreate
                  show={modalShowFile}
                  onHide={() => setModalShowFile(false)}
                  parent={lead}
                  table="medicaltest"
                  submitFiles={submitFiles}
                />
              )}

              {reletedListTask && (
                <div>
                  <Button
                    className="float-right btn-sm mx-2"
                    onClick={() => setModalShowTask(true)}
                  >
                    New Task
                  </Button>

                  <Button
                    className="float-right btn-sm"
                    onClick={() => setModalShowEmail(true)}
                    variant="success"
                  >
                    <i class="fa-regular fa-envelope mx-2"></i>
                    Send Email
                  </Button>
                </div>
              )}
              {modalShowTask && (
                <TaskEdit
                  show={modalShowTask}
                  onHide={() => setModalShowTask(false)}
                  parentid={lead?.id}
                  parenttable={"lead"}
                  table="lead"
                  submitTasks={submitTasks}
                />
              )}
            </Card.Header>
            <Card.Body>
              {relatedListFiles && (
                <RelatedListFiles
                  refreshFileList={refreshFileList}
                  parent={lead}
                />
              )}
              {reletedListTask ? (
                <RelatedListTask
                  refreshTaskList={refreshTaskList}
                  parent={lead}
                />
              ) : (
                ""
              )}

              {releatedlistlocation ? (
                <RelatedListLocationHistory
                  refreshTaskList={refreshTaskList}
                  parent={lead}
                />
              ) : (
                ""
              )}
            </Card.Body>
          </Card>

          <EmailComposer
            size="lg"
            show={modalShowEmail}
            onHide={() => setModalShowEmail(false)}
            parentid={lead?.id}
            toEmail={lead?.email}
            table="lead"
            submitTasks={submitTasks}
          />
        </>
      )}

      <Modal show={convertSalesAmount} onHide={() => setconvertSalesAmount(false)}>
        {
          isSpinner
            ?
            <Modal.Body className="p-5 text-center">

              <Spinner animation="border" />
            </Modal.Body>
            :
            <>
              <Modal.Header closeButton>
                <Modal.Title>Sales Amount</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Container className="view-form">

                  <Form noValidate validated={validated} onSubmit={handleOnSubmit}>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                      <Form.Label>Sales Amount</Form.Label>
                      <Form.Control type="text" name="amount"
                        // New Change : remove the defaut value
                        // defaultValue={lead?.amount && lead?.amount}
                        required onChange={handleSalesAmountCahnge} />
                      <Form.Control.Feedback type="invalid">
                        Enter sales amount.
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group  >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                      >
                        Full Advance
                      </Form.Label>
                      <Form.Control
                        required={true}
                        type="text"
                        name="fulladvance"
                        placeholder="Enter Full Advance"

                        //defaultValue="Ali"
                        onChange={handleSalesAmountCahnge}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Full Advance.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group  >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                      >
                        Percentage of Advance
                      </Form.Label>
                      <Form.Control

                        type="text"
                        name="percentageofadvance"
                        placeholder="Enter Percentage of Advance"

                        //defaultValue="Ali"
                        onChange={handleSalesAmountCahnge}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Percentage of Advance.
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group  >
                      <Form.Label
                        className="form-view-label"
                        htmlFor="formBasicLastName"
                      >
                        Value of Advance
                      </Form.Label>
                      <Form.Control

                        type="text"
                        name="valueofadvance"
                        placeholder="Enter Value of Advance"

                        //defaultValue="Ali"
                        onChange={handleSalesAmountCahnge}
                      />
                      <Form.Control.Feedback type="invalid">
                        Enter Value of Advance.
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Form>
                </Container>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="success" onClick={handleOnSubmit}>
                  Save
                </Button>
                <Button variant="secondary" onClick={() => setconvertSalesAmount(false)}>
                  Close
                </Button>
              </Modal.Footer>
            </>}
      </Modal>
    </Container >
  );
};
export default LeadView;
